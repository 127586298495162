import react, { useEffect, useStatus, useState } from 'react';
import baseUrl from '../../slices/baseUrl';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../../slices/loginSlice';
import { getUserData } from '../../slices/userSlice';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import '../messageModal/messageModal.css';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    IconButton,
    Alert
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import MessageModal from '../messageModal/MessageModal';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login(props) {
    const { onLogin } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [msgOpen, setMsgOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorMsgForgotPwd, setErrorMsgForgotPwd] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // const loginStatusResult = useSelector(state=>state.login.loginStatus)
    // const userDataResult = useSelector(state=>state.user.userData)

    const handleLogin = async event => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('userName');
        const password = data.get('password');
        if (!username) {
            setMsgOpen(true);
            setErrorMessage('Please enter User Name');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (!password) {
            setMsgOpen(true);
            setErrorMessage('Please enter password');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else {
            let loginData = {
                username: data.get('userName'),
                password: data.get('password')
            };

            dispatch(login(loginData))
                .then(result => {
                    if (result.payload === true) {
                        dispatch(getUserData(username)).then(userData => {
                            if (userData.payload.username === username) {
                                onLogin();
                                sessionStorage.setItem(
                                    'userId',
                                    userData.payload.id
                                );
                                sessionStorage.setItem(
                                    'role',
                                    userData.payload.role
                                );
                                if (userData.payload.role === 'Manager') {
                                    navigate('/file-data-manager');
                                    // navigate('/file-data');
                                } else {
                                    navigate('/file-data');
                                }
                            }
                        });
                    } else {
                        setErrorMsg(result?.error?.message);
                    }
                })
                .catch(error => {
                    setErrorMsg(error?.error?.message);
                    setErrorMsg(error?.response?.message);
                });
        }
    };

    const handleErrorClear = () => {
        setErrorMsg(prevValue => (prevValue = ''));
    };
    const handlePwdErrorClear = () => {
        setErrorMsgForgotPwd(prevValue => (prevValue = ''));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEmail('');
        setNewPassword('');
        setConfirmPassword('');
    };
    const handleMsgClose = () => {
        setMsgOpen(false);
    };
    const handleSuccessClose = () => {
        setSuccessOpen(false);
    };

    const handleEmailChange = e => {
        setEmail(preveMail => (preveMail = e.target.value));
    };

    const handleNewPasswordChange = e => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevShowPassword => !prevShowPassword);
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setMsgOpen(true);
            setErrorMessage('Please enter email');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (!newPassword) {
            setMsgOpen(true);
            setErrorMessage('Please enter New password');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (!confirmPassword) {
            setMsgOpen(true);
            setErrorMessage('Please enter Confirm password');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (newPassword !== confirmPassword) {
            setMsgOpen(true);
            setErrorMessage('Password and Confirm password are not same');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else {
            await axios
                .put(`${baseUrl}/api/forgetpassword/${email}`, {
                    password: newPassword
                })
                .then(response => {
                    if (response.status === 200) {
                        setMessage('Password reset successfully');
                        showMessageHandler();
                        setEmail('');
                        setNewPassword('');
                        setConfirmPassword('');
                        setSuccessOpen(true);
                        setTimeout(() => {
                            handleSuccessClose();
                            handleClose();
                        }, 2000);
                    }
                })
                .catch(colMapError => {
                    setMessage(colMapError);
                    setErrorMsgForgotPwd(colMapError?.response?.data);
                    showMessageHandler();
                });
        }
    };
    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleLogin}
                        noValidate
                        sx={{ mt: 1 }}
                        onChange={handleErrorClear}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="User Name"
                            name="userName"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                handleTogglePasswordVisibility
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {errorMsg && (
                            <Box mt="10px">
                                <Alert severity="error">{errorMsg}</Alert>
                            </Box>
                        )}
                        {msgOpen && (
                            <Dialog open={msgOpen} onClose={handleMsgClose}>
                                <DialogTitle>Alert !</DialogTitle>
                                <DialogContent>
                                    {/* <p>Password and Confirm password are not same </p> */}
                                    <p>{errorMessage}</p>
                                </DialogContent>
                            </Dialog>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        >
                            Sign In
                        </Button>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            {/* <Grid item xs={4.5}> */}
                            <Link
                                sx={{ marginRight: '10px' }}
                                href="#"
                                variant="body2"
                                onClick={handleClickOpen}
                            >
                                Forgot password?
                            </Link>
                            {/* </Grid>
                            <Grid item xs={7.5}> */}
                            {/* <Link href="registration" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link> */}
                            {/* </Grid> */}
                        </Box>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>

            <Dialog
                open={open}
                onClose={handleClose}
                onChange={handlePwdErrorClear}
            >
                <DialogTitle>Forgot Password</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{ fontSize: '13px', textAlign: 'center' }}
                    >
                        Please enter your email address to update password
                    </DialogContentText>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Grid item xs={4}>
                            <Typography
                                sx={{ marginRight: '10px', fontSize: '13px' }}
                            >
                                Email Address
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                autoComplete="off"
                                size="small"
                                autoFocus
                                margin="dense"
                                id="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Grid item xs={4}>
                            <Typography
                                sx={{ marginRight: '10px', fontSize: '13px' }}
                            >
                                New Password
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                autoComplete="off"
                                size="small"
                                margin="dense"
                                id="newPassword"
                                label="New Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={
                                                    handleTogglePasswordVisibility
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Grid item xs={4}>
                            <Typography
                                sx={{ marginRight: '10px', fontSize: '13px' }}
                            >
                                Confirm New Password
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                autoComplete="off"
                                size="small"
                                margin="dense"
                                id="confirmPassword"
                                label="Confirm New Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                fullWidth
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={
                                                    handleToggleConfirmPasswordVisibility
                                                }
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    {errorMsgForgotPwd && (
                        <Box mt="10px">
                            <Alert severity="error">{errorMsgForgotPwd}</Alert>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            textTransform: 'none',
                            marginRight: '10px',
                            backgroundColor: 'rgb(200,50,50)',
                            '&:hover': {
                                backgroundColor: 'rgb(139,0,0)'
                            }
                        }}
                        size="small"
                        onClick={handleClose}
                        color="primary"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            textTransform: 'none'
                            // backgroundColor: 'rgb(50,250,50)'
                        }}
                        size="small"
                        onClick={handleForgotPassword}
                        color="primary"
                        variant="contained"
                    >
                        Submit
                    </Button>
                </DialogActions>

                {msgOpen && (
                    <Dialog open={msgOpen} onClose={handleMsgClose}>
                        <DialogTitle>Alert !</DialogTitle>
                        <DialogContent>
                            {/* <p>Password and Confirm password are not same </p> */}
                            <p>{errorMessage}</p>
                        </DialogContent>
                    </Dialog>
                )}
                {successOpen && (
                    <Dialog open={successOpen} onClose={handleSuccessClose}>
                        <DialogTitle>Success !</DialogTitle>
                        <DialogContent>
                            <p>Password updated successfully!</p>
                        </DialogContent>
                    </Dialog>
                )}
            </Dialog>
        </ThemeProvider>
    );
}
