import React, { useRef, useState, useEffect, useContext } from 'react';
import MessageModal from '../messageModal/MessageModal';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Button,
    TextField,
    InputLabel,
    useTheme,
    IconButton,
    Grid,
    Typography,
    Tooltip,
    Stack,
    Modal,
    Alert,
    Pagination
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import baseUrl from '../../slices/baseUrl';
import Header from '../../components/Header';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { tokens } from '../../theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { updateSupplier } from '../../slices/supplierSlice';
import {
    fetchAllSuppliersPageWise,
    deleteSupplier,
    fetchTotalSupplierCount
} from '../../slices/supplierSlice';

const Form = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [idToDelete, setIdToDelete] = useState('');
    const [data, setData] = useState([]);
    const totalRows = useSelector(state => state.supplier.totalRows);
    const colors = tokens(theme.palette.mode);
    const [errorMsg, setErrorMsg] = useState('');

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputPage, setInputPage] = useState(1);
    const [editingSupplierId, setEditingSupplierId] = useState(null);
    const [errorUpdateMsg, setErrorUpdateMsg] = useState('');

    const [newSupplier, setNewSupplier] = useState({
        firstName: '',
        lastName: '',
        mobileNo: '',
        email: '',
        address: '',
        organisationName: ''
    });
    const allSuppliers = useSelector(state => state?.supplier?.suppliers);
    // const error = useSelector(state => state?.supplier?.error);

    useEffect(() => {
        dispatch(fetchAllSuppliersPageWise(currentPage));
        fetchTotalSupplierCount();
        // dispatch(fetchAllSuppliers(currentPage));
        // getTotalRows();
    }, [currentPage]);

    useEffect(() => {
        if (totalRows > 1) {
            setTotalPages(prevPages => (prevPages = Math.ceil(totalRows / 20)));
        }
    }, [totalRows]);

    // convert miliseconds to date and time
    function convertMillisecondsToDateTime(milliseconds) {
        const date = new Date(milliseconds);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const formattedDateTime = date.toLocaleString(undefined, options);
        return formattedDateTime;
    }

    // Add new supplier
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMsg(prevValue => (prevValue = ''));
    };

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const response = await axios.post(
                `${baseUrl}/api/user/addSupplier`,
                values
            );
            if (response.status === 200) {
                // setLoading(false);
                setMessage('Supplier successfully added');
                showMessageHandler();

                resetForm();
                setOpen(false);
                setTimeout(() => {
                    dispatch(fetchAllSuppliersPageWise(currentPage));
                    // getSuppliersList(currentPage);
                }, 1500);
            }
        } catch (error) {
            console.error(error?.response?.data);
            setErrorMsg(error?.response?.data);
        }
    };

    // Display message
    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    // Pagination
    const goToPage = () => {
        let newPage = Number(inputPage);

        if (newPage < 1) {
            setMessage('The minimum page value is 1');
            showMessageHandler();
        } else if (newPage > totalPages) {
            setMessage('The value exceeds the maximum page count');
            showMessageHandler();
        } else {
            setCurrentPage(prevPage => (prevPage = newPage));
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(prevPage => (prevPage = newPage));
        setInputPage(newPage);
    };

    const handleEditClick = item => {
        setEditingSupplierId(item.id);
        setNewSupplier(item);
    };

    const handleCancelEdit = () => {
        setEditingSupplierId(null);
        setNewSupplier({ userName: '', userEmail: '', userRole: '' });
        setErrorUpdateMsg(prevValue => (prevValue = ''));
    };

    const handleSaveEdit = newSupplier => {
        const updatedUser = {
            ...newSupplier
        };
        axios
            .put(
                `${baseUrl}/api/user/updateUser/${newSupplier.id}`,
                updatedUser
            )
            .then(response => {
                if (response.status === 200) {
                    setEditingSupplierId(null);
                    setNewSupplier({});
                    setMessage(`Supplier updated successfully`);
                    showMessageHandler();
                    dispatch(fetchAllSuppliersPageWise(currentPage));
                }
            })
            .catch(error => {
                console.error(error);
                setErrorUpdateMsg(error?.response?.data);
            });
    };

    const handleUpdateErrorClear = () => {
        setErrorUpdateMsg('');
    };

    const handleDeleteOpen = id => {
        setDeleteOpen(true);
        setIdToDelete(id);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setIdToDelete('');
    };

    const handleOkDelete = () => {
        dispatch(deleteSupplier(idToDelete));
        setIdToDelete('');
        handleDeleteClose();
    };

    const myStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#d0d0d0',
        width: '40vw',
        height: '150px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        outline: 'none'
    };

    const handleErrorClear = () => {
        setErrorMsg(prevValue => (prevValue = ''));
    };

    return (
        <Box m="20px">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputLabel
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        '@media (max-width: 712px)': { fontSize: 15 }
                    }}
                >
                    Suppliers list
                </InputLabel>
                <Button
                    sx={{
                        width: '150px',
                        bgcolor: '#555555',
                        color: '#ffffff',
                        boxShadow: 'none',
                        textTransform: 'none',
                        '&:hover': {
                            bgcolor: '#111111',
                            color: '#ffffff'
                        }
                    }}
                    onClick={handleClickOpen}
                >
                    Add Supplier
                </Button>
            </Box>

            {/* Supplier list */}
            <Box
                sx={{
                    width: '100%',
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    lineheight: '5px'
                }}
            >
                <Grid
                    container
                    className="grid-table-header"
                    style={{
                        backgroundColor: colors.grey[800],
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        border: `0.5px solid ${colors.grey[800]}`,
                        outline: 'none',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }}
                    sx={{
                        '& .MuiGrid-item': {
                            padding: '6px'
                        }
                    }}
                >
                    <Grid item xs={0.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            ID
                        </Typography>
                    </Grid>
                    <Grid item xs={1.4}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            First Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1.4}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Last Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Mobile No
                        </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={1.8}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Organization Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Created on
                        </Typography>
                    </Grid>
                    <Grid item xs={0.9}>
                        <Typography
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            Action
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    lineheight: '5px'
                }}
            >
                <Box sx={{ width: '100%' }}>
                    {allSuppliers?.map((item, index) => (
                        <div key={item?.id}>
                            {editingSupplierId === item.id ? (
                                <>
                                    <Grid
                                        container
                                        onChange={handleUpdateErrorClear}
                                        className="grid-table-container"
                                        style={{
                                            borderBottomLeftRadius:
                                                index ===
                                                allSuppliers.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            borderBottomRightRadius:
                                                index ===
                                                allSuppliers.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            border: `0.5px solid ${colors.grey[800]}`,
                                            borderCollapse: 'collapse',
                                            outline: 'none',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? 'inherit'
                                                    : colors.primary[400]
                                        }}
                                        sx={{
                                            '& .MuiGrid-item': {
                                                padding: '6px'
                                            }
                                        }}
                                    >
                                        <Grid item xs={0.5}>
                                            <Typography
                                                style={{
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.4}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newSupplier.firstName}
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        firstName:
                                                            e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1.4}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newSupplier.lastName}
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        lastName:
                                                            e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newSupplier.mobileNo}
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        mobileNo:
                                                            e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newSupplier.email}
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        email: e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1.8}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newSupplier.address}
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        address:
                                                            e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <TextField
                                                required
                                                size="small"
                                                value={
                                                    newSupplier.organisationName
                                                }
                                                onChange={e =>
                                                    setNewSupplier({
                                                        ...newSupplier,
                                                        organisationName:
                                                            e.target.value.trim()
                                                    })
                                                }
                                                sx={{
                                                    '& input': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {convertMillisecondsToDateTime(
                                                    item?.createdOn
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={0.9}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                padding: '0px'
                                            }}
                                        >
                                            <Tooltip title="Save">
                                                <IconButton
                                                    sx={{
                                                        '&:hover': {
                                                            color: '#6870fa'
                                                        }
                                                    }}
                                                    onClick={() =>
                                                        handleSaveEdit(
                                                            newSupplier
                                                        )
                                                    }
                                                >
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <IconButton
                                                    sx={{
                                                        // mr: 1,
                                                        '&:hover': {
                                                            color: 'red'
                                                        }
                                                    }}
                                                    onClick={handleCancelEdit}
                                                >
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        {errorUpdateMsg && (
                                            <Box mt="10px">
                                                <Alert severity="error">
                                                    {errorUpdateMsg}
                                                </Alert>
                                            </Box>
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <Grid
                                    container
                                    className="grid-table-container"
                                    style={{
                                        borderBottomLeftRadius:
                                            index === allSuppliers.length - 1
                                                ? '5px'
                                                : '0px',
                                        borderBottomRightRadius:
                                            index === allSuppliers.length - 1
                                                ? '5px'
                                                : '0px',
                                        border: `0.5px solid ${colors.grey[800]}`,
                                        borderCollapse: 'collapse',
                                        outline: 'none',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        backgroundColor:
                                            index % 2 === 0
                                                ? 'inherit'
                                                : colors.primary[400]
                                    }}
                                    sx={{
                                        '& .MuiGrid-item': {
                                            padding: '6px'
                                        }
                                    }}
                                >
                                    <Grid item xs={0.5}>
                                        <Typography
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.4}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.firstName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.4}>
                                        <Typography
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.mobileNo}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.8}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {' '}
                                            {item?.address}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.organisationName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {convertMillisecondsToDateTime(
                                                item?.createdOn
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={0.9}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            padding: '0px'
                                        }}
                                    >
                                        <Tooltip title="Edit">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={e =>
                                                    handleEditClick(item)
                                                }
                                            >
                                                <EditIcon
                                                    sx={{
                                                        '&:hover': {
                                                            color: '#6870fa'
                                                        },
                                                        fontSize: '1rem'
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Delete"
                                            // placement="right"
                                        >
                                            <IconButton
                                                aria-label="delete"
                                                onClick={e =>
                                                    handleDeleteOpen(item?.id)
                                                }
                                            >
                                                <DeleteIcon
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'red'
                                                        },
                                                        fontSize: '1rem'
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    ))}
                </Box>
            </Box>

            {/* Pagination */}
            <Grid
                container
                style={{
                    display: 'flex',
                    overflow: 'hidden',
                    position: 'sticky',
                    bottom: '-17px',
                    left: '-10px',
                    right: 0,
                    margin: '20px 0 -30px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary[400],
                    padding: '7px',
                    zIndex: 11
                }}
            >
                <Stack
                    spacing={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                    <Box style={{ marginLeft: '10px', marginTop: '0px' }}>
                        <span>| Go to page : &nbsp;</span>
                        <input
                            type="number"
                            value={inputPage === 0 ? '' : inputPage}
                            onChange={e =>
                                setInputPage(
                                    prevPage =>
                                        (prevPage = Number(e.target.value))
                                )
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    goToPage();
                                    e.target.blur();
                                }
                            }}
                            style={{
                                width: '50px',
                                height: '30px',
                                backgroundColor: 'inherit',
                                border: '0.5px solid grey',
                                borderRadius: '3px',
                                color: colors.primary[100]
                            }}
                            min="1"
                            max={totalPages}
                        />
                        <Button
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: '#555555',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#666666'
                                },
                                width: '40px'
                            }}
                            onClick={goToPage}
                        >
                            Go
                        </Button>
                    </Box>
                </Stack>
            </Grid>

            {/* Add supplier dialog */}
            {open && (
                <Dialog
                    open={open}
                    // TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>
                        Add new supplier{' '}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'red'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    onChange={handleErrorClear}
                                >
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            '& > div': {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : 'span 4'
                                            }
                                        }}
                                    >
                                        <TextField
                                            autoFocus
                                            required
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="text"
                                            label="First Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstName}
                                            name="firstName"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <TextField
                                            required
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            size="small"
                                            label="Last Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastName}
                                            name="lastName"
                                            sx={{ gridColumn: 'span 2' }}
                                        />

                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="text"
                                            label="Contact Number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.mobileNo}
                                            name="mobileNo"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <TextField
                                            required
                                            size="small"
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="text"
                                            label="Address"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.address}
                                            name="address"
                                            sx={{ gridColumn: 'span 4' }}
                                        />
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            type="text"
                                            label="Organization Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.organisationName}
                                            name="organisationName"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        mt="20px"
                                    ></Box>
                                    {errorMsg && (
                                        <Box mt="10px">
                                            <Alert severity="error">
                                                {errorMsg}
                                            </Alert>
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gridColumn: 'span 4'
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            style={{
                                                textTransform: 'none',
                                                fontSize: '14px',
                                                fontWeight: 'bold'
                                            }}
                                            // onSubmit={handleFormSubmit}
                                        >
                                            Create New Supplier
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
            )}

            {deleteOpen && (
                <div>
                    <Modal
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={myStyle}>
                            <Typography
                                id="modal-modal-title"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                                variant="body1"
                                component="h2"
                            >
                                Are you sure to delete this Supplier?
                            </Typography>
                            <Box
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    marginTop: '20px'
                                }}
                            >
                                <Button
                                    style={{
                                        width: '105px',
                                        backgroundColor: '#555555',
                                        borderRadius: '5px',
                                        border: '1px solid #555555',
                                        boxShadow: 'none'
                                    }}
                                    onClick={e => handleOkDelete()}
                                    type="submit"
                                    variant="contained"
                                >
                                    Ok
                                </Button>{' '}
                                <Button
                                    type="cancel"
                                    onClick={handleDeleteClose}
                                    style={{
                                        fontSize: '16px',
                                        textTransform: 'none',
                                        width: '100px',
                                        color: '#555555',
                                        backgroundColor: 'inherit',
                                        border: '1px solid #555555',
                                        borderRadius: '5px'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            )}

            {showMessage && (
                <MessageModal
                    message={message}
                    setShowMessage={setShowMessage}
                />
            )}
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    email: yup.string().email('invalid email').required('required'),
    mobileNo: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('required'),
    address: yup.string().required('required'),
    organisationName: yup.string().required('required')
});
const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    address: '',
    organisationName: ''
};

export default Form;
