// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from './baseUrl';
import axios from 'axios';

export const fetchAllOrders = createAsyncThunk(
    'orders/fetchAllOrders',
    async currentPage => {
        const response = await axios.get(
            `${baseUrl}/api/mail/getListOfMailHistory/${currentPage}`
        );
        return response.data;
    }
);

export const fetchOrderRowsCount = createAsyncThunk(
    'orders/fetchOrderRowsCount',
    async userId => {
        const response = await axios.get(`${baseUrl}/api/mail/count`);
        if (response.data > 0) {
            return response.data;
        }
    }
);

const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        status: 'idle',
        error: null,
        totalRows: 0
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllOrders.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAllOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload.sort(
                    (a, b) => b.createdOn - a.createdOn
                );
            })
            .addCase(fetchAllOrders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchOrderRowsCount.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchOrderRowsCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.totalRows = action.payload;
            })
            .addCase(fetchOrderRowsCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default ordersSlice.reducer;
