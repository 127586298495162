import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {
    Box,
    List,
    ListItem,
    TextField,
    Grid,
    ListItemText,
    Typography,
    useTheme,
    InputLabel,
    Button,
    Pagination,
    Stack
} from '@mui/material';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import { fetchAllOrders, fetchOrderRowsCount } from '../../slices/ordersSlice';
import MessageModal from '../messageModal/MessageModal';

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const totalRows = useSelector(state => state.orders.totalRows);
    const [inputPage, setInputPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch();
    const allOrders = useSelector(state => state.orders.orders);

    // useEffect(() => {
    //     dispatch(fetchAllOrders(currentPage));
    //     dispatch(fetchOrderRowsCount());
    // }, []);

    useEffect(() => {
        dispatch(fetchAllOrders(currentPage));
        dispatch(fetchOrderRowsCount());
    }, [currentPage]);

    useEffect(() => {
        if (totalRows > 1) {
            setTotalPages(prevPages => (prevPages = Math.ceil(totalRows / 20)));
        }
    }, [totalRows]);

    useEffect(() => {
        if (allOrders?.length > 0) {
            setData(allOrders);
        }
    }, [allOrders]);

    function convertMillisecondsToDateTime(milliseconds) {
        const date = new Date(milliseconds);

        // Customize the date and time format as needed
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        const formattedDateTime = date.toLocaleString(undefined, options);
        return formattedDateTime;
    }

    const goToPage = () => {
        let newPage = Number(inputPage);

        if (newPage < 1) {
            setMessage('The minimum page value is 1');
            showMessageHandler();
        } else if (newPage > totalPages) {
            setMessage('The value exceeds the maximum page count');
            showMessageHandler();
        } else {
            setCurrentPage(prevPage => (prevPage = newPage));
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(prevPage => (prevPage = newPage));
        setInputPage(newPage);
    };

    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    return (
        <div
            style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <InputLabel
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        '@media (max-width: 712px)': { fontSize: 15 }
                    }}
                >
                    Orders
                </InputLabel>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    // marginTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    lineheight: '5px'
                }}
            >
                <Grid
                    container
                    className="grid-table-header"
                    style={{
                        backgroundColor: colors.grey[800],
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        border: `0.5px solid ${colors.grey[800]}`,
                        outline: 'none',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }}
                    sx={{
                        '& .MuiGrid-item': {
                            padding: '6px'
                        }
                    }}
                >
                    <Grid item xs={0.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            ID
                        </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Product Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            UPC
                        </Typography>
                    </Grid>
                    <Grid item xs={0.8}>
                        <Typography
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                textAlign: 'right'
                            }}
                        >
                            Price ($)
                        </Typography>
                    </Grid>

                    <Grid item xs={0.8}>
                        <Typography
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                textAlign: 'right'
                            }}
                        >
                            Quantity
                        </Typography>
                    </Grid>
                    <Grid item xs={0.2}></Grid>
                    <Grid item xs={1.3}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Supplier
                        </Typography>
                    </Grid>
                    <Grid item xs={1.3}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Organization
                        </Typography>
                    </Grid>
                    <Grid item xs={2.2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Date
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    lineheight: '5px'
                }}
                // style={{
                //     border: '1px solid grey',
                //     borderBottomLeftRadius: '2px',
                //     borderBottomRightRadius: '2px'
                // }}
            >
                <Box sx={{ width: '100%' }}>
                    {data?.map((item, index) => (
                        <div key={item?.id}>
                            <Grid
                                container
                                className="grid-table-container"
                                style={{
                                    borderBottomLeftRadius:
                                        index === data.length - 1
                                            ? '5px'
                                            : '0px',
                                    borderBottomRightRadius:
                                        index === data.length - 1
                                            ? '5px'
                                            : '0px',
                                    border: `0.5px solid ${colors.grey[800]}`,
                                    borderCollapse: 'collapse',
                                    outline: 'none',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    backgroundColor:
                                        index % 2 === 0
                                            ? 'inherit'
                                            : colors.primary[400]
                                }}
                                sx={{
                                    '& .MuiGrid-item': {
                                        padding: '6px'
                                    }
                                }}
                            >
                                <Grid item xs={0.5}>
                                    <Typography
                                        style={{
                                            // paddingLeft: '5px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {item?.id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {item?.productName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                    <Typography
                                        style={{
                                            // paddingLeft: '5px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {item?.upc}
                                    </Typography>
                                </Grid>
                                <Grid item xs={0.8}>
                                    <Typography
                                        style={{
                                            // paddingLeft: '5px',
                                            fontSize: '12px',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {item?.price}
                                    </Typography>
                                </Grid>
                                {/* <Grid item xs={0.2}></Grid> */}
                                <Grid item xs={0.8}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {item?.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={0.2}></Grid>
                                <Grid item xs={1.3}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {item?.firstName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.3}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {' '}
                                        {item?.organisationName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.2}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {item?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {convertMillisecondsToDateTime(
                                            item.createdOn
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Box>
            </Box>
            <Grid
                container
                style={{
                    display: 'flex',
                    overflow: 'hidden',
                    // width: '94vw',
                    position: 'sticky',
                    bottom: '-17px',
                    left: '-10px',
                    right: 0,
                    margin: '20px 0 -30px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary[400],
                    padding: '7px',
                    zIndex: 11
                }}
            >
                <Stack
                    spacing={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                    <Box style={{ marginLeft: '10px', marginTop: '0px' }}>
                        <span>| Go to page : &nbsp;</span>
                        <input
                            type="number"
                            value={inputPage === 0 ? '' : inputPage}
                            // onChange={e => {
                            //     let newPage = e.target.value
                            //         ? Math.min(
                            //               Math.max(Number(e.target.value), 1),
                            //               totalPages
                            //           )
                            //         : 1;
                            // }}
                            onChange={e =>
                                setInputPage(
                                    prevPage =>
                                        (prevPage = Number(e.target.value))
                                )
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    goToPage();
                                    e.target.blur(); // Remove focus from the input field
                                }
                            }}
                            style={{
                                width: '50px',
                                height: '30px',
                                backgroundColor: 'inherit',
                                border: '0.5px solid grey',
                                borderRadius: '3px',
                                color: colors.primary[100]
                            }}
                            min="1"
                            max={totalPages}
                        />
                        <Button
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: '#555555',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#666666'
                                },
                                width: '40px'
                            }}
                            onClick={goToPage}
                        >
                            Go
                        </Button>
                    </Box>
                </Stack>
            </Grid>
        </div>
    );
};

export default Calendar;
