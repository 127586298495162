// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from './baseUrl';
import axios from 'axios';

// Define your initial state
// 1 fetch getUserdata by name
// 1 fetch all users = fetchUsers
// 2 fetch all users by client Id = fetchAllUsersByClientId
// 3 fetch single user by client Id = fetchUsersByClientId
// 4 fetch user by user id = fetchUser
// 5 create user = createUser
// 6 upddate user = updateUser
// 7 delete user = deleteUser
// 8 delete user by userId = deleteUserByUserId
// 9 searchUsersByClientId
// 10 searchAllUsers

const initialState = {
    users: [],
    userId: '',
    allUsersByClientId: [],
    user: '',
    search: [],
    userData: null,
    deleteAdmin: false,
    deleteUser: false,
    status: 'idle',
    error: null
};

// Create an async thunk for fetching users data
export const getUserData = createAsyncThunk(
    'users/getUserData',
    async username => {
        const response = await axios(`${baseUrl}/api/getUserData/${username}`);
        return response.data;
    }
);

export const fetchAllUsersPageWise = createAsyncThunk(
    'suppliers/fetchAllUsers',
    async currentPage => {
        const response = await axios.get(
            `${baseUrl}/api/user/getAllList/${currentPage}`
        );
        return response.data.sort((a, b) => a.createdOn - b.createdOn);
    }
);

export const updateUser = createAsyncThunk('users/updateUser', async user => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/user/updateUser/${user.id}`,
            user,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        console.log('response.data', response.data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error(error);
        throw error;
    }
});

export const fetchTotalUserRows = createAsyncThunk(
    'data/fetchTotalUserRows',
    async userId => {
        const response = await axios.get(`${baseUrl}/api/mail/count`);
        if (response.data > 0) {
            return response.data;
        }
    }
);

// Create an async thunk for deleting a user
export const deleteUser = createAsyncThunk('users/deleteUser', async userId => {
    await fetch(`${baseUrl}/api/user/deleteSupplierAndAdmin/${userId}`, {
        method: 'DELETE'
    });
    return userId;
});

// export const searchAllUsers = createAsyncThunk(
//     'users/searchAllUsers',
//     async searchText => {
//         const response = await axios.get(
//             `${baseUrl}/api/users/searchAll?searchTerm=${searchText.trim()}`
//         );
//         return response.data;
//     }
// );

const userSlice = createSlice({
    name: 'user',
    initialState: {
        suppliers: [],
        orders: [],
        status: 'idle',
        error: null,
        users: [],
        totalRows: 0
    },
    reducers: {},

    extraReducers: builder => {
        builder
            .addCase(getUserData.pending, state => {
                state.status = 'loading';
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchTotalUserRows.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchTotalUserRows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.totalRows = action.payload;
            })
            .addCase(fetchTotalUserRows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchAllUsersPageWise.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAllUsersPageWise.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.sort((a, b) => b.id - a.id);
            })
            .addCase(fetchAllUsersPageWise.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(updateUser.pending, state => {
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Update the user data in the state directly
                const updatedUser = action.payload;
                state.users = state.users.map(user => {
                    return user.id === updatedUser.id ? updatedUser : user;
                });
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(deleteUser.pending, state => {
                state.status = 'loading';
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Delete the user from the state directly
                const deletedUserId = action.payload;
                state.users = state.users.filter(
                    user => user.id !== deletedUserId
                );
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        // builder
        //     .addCase(searchAllUsers.pending, state => {
        //         state.status = 'loading';
        //     })
        //     .addCase(searchAllUsers.fulfilled, (state, action) => {
        //         state.status = 'succeeded';
        //         state.search = action.payload;
        //     })
        //     .addCase(searchAllUsers.rejected, (state, action) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });
    }
});

export default userSlice.reducer;
