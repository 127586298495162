// dataSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from './baseUrl';

// Define an initial state
const initialState = {
    records: [],
    search: [],
    userData: [],
    mailSentStatus: null,
    totalRows: 0,
    status: 'idle',
    error: null
};

// export const fetchRecords = createAsyncThunk(
//     'data/fetchRecords',
//     async ({ userId, currentPage }) => {
//         const response = await axios.get(
//             `${baseUrl}/api/files/getData/${currentPage}/${userId}`
//         ); // Replace with your API endpoint

//         return response.data;
//     }
// );

export const fetchAllRecords = createAsyncThunk(
    'data/fetchAllRecords',
    async currentPage => {
        const response = await axios.get(
            `${baseUrl}/api/files/getAllData/${currentPage}`
        );
        return response.data;
    }
);
export const sendMail = createAsyncThunk('data/sendMail', async obj => {
    const response = await axios.post(
        `${baseUrl}/api/mail/mailToSupplier`,
        obj
    );

    return response.data;
});

export const resetSendMail = createAsyncThunk('resetSendMail', async () => {
    return null;
});

export const fetchNameAndEmailById = createAsyncThunk(
    'data/fetchNameAndEmailById',
    async userId => {
        const response = await axios.get(
            `${baseUrl}/api/files/getDataByUserId/${userId}`
        );
        return response.data;
    }
);

export const fetchSortedRecords = createAsyncThunk(
    'data/fetchSortedRecords',
    async ({ sortOrder, currentPage }) => {
        const response = await axios.get(
            `${baseUrl}/api/files/file-operations/sort/${sortOrder}/${currentPage}`
        );
        return response.data;
    }
);

export const fetchTotalRows = createAsyncThunk(
    'data/fetchTotalRows',
    async userId => {
        const response = await axios.get(
            `${baseUrl}/api/files/file-operations/count`
        );
        if (response.data > 0) {
            return response.data;
        }
    }
);

export const fetchSearchedRows = createAsyncThunk(
    'data/fetchSearchedRows',
    async searchText => {
        const response = await axios.get(
            `${baseUrl}/api/files/file-operations/search?searchTerm=${searchText.trim()}`
        );

        return response.data;
    }
);

// Create a slice
const fileDataSlice = createSlice({
    name: 'data',
    initialState,

    reducers: {},
    userData: {},
    extraReducers: builder => {
        // builder
        //     .addCase(fetchRecords.pending, state => {
        //         state.status = 'loading';
        //     })
        //     .addCase(fetchRecords.fulfilled, (state, action) => {
        //         state.status = 'succeeded';
        //         state.records = action.payload;
        //     })
        //     .addCase(fetchRecords.rejected, (state, action) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });
        builder
            .addCase(fetchTotalRows.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchTotalRows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.totalRows = action.payload;
            })
            .addCase(fetchTotalRows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchAllRecords.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAllRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records = action.payload;
            })
            .addCase(fetchAllRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchSortedRecords.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchSortedRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records = action.payload;
            })
            .addCase(fetchSortedRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchSearchedRows.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchSearchedRows.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.search = action.payload;
                state.totalRows = action.payload.length;
            })
            .addCase(fetchSearchedRows.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchNameAndEmailById.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchNameAndEmailById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.search = action.payload;
                state.userData = action.payload.length;
            })
            .addCase(fetchNameAndEmailById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(sendMail.pending, state => {
                state.status = 'loading';
            })
            .addCase(sendMail.fulfilled, (state, action) => {
                state.mailSentStatus = action.payload;
            })
            .addCase(sendMail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(resetSendMail.pending, state => {
                state.status = 'loading';
            })
            .addCase(resetSendMail.fulfilled, (state, action) => {
                state.mailSentStatus = action.payload;
            })
            .addCase(resetSendMail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default fileDataSlice.reducer;
