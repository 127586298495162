// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from './baseUrl';
import axios from 'axios';

// Define your initial state
// 1 fetch getUserdata by name
// 1 fetch all users = fetchUsers
// 2 fetch all users by client Id = fetchAllUsersByClientId
// 3 fetch single user by client Id = fetchUsersByClientId
// 4 fetch user by user id = fetchUser
// 5 create user = createUser
// 6 upddate user = updateUser
// 7 delete user = deleteUser
// 8 delete user by userId = deleteUserByUserId
// 9 searchUsersByClientId
// 10 searchAllUsers

const initialState = {
    suppliers: [],
    userId: '',
    search: [],
    userData: null,
    deleteSupplier: false,
    status: 'idle',
    error: null
};

// Create an async thunk for fetching users
export const getUserData = createAsyncThunk(
    'users/getUserData',
    async username => {
        const response = await axios(`${baseUrl}/api/getUserData/${username}`);
        return response.data;
    }
);

export const fetchAllSuppliers = createAsyncThunk(
    'suppliers/fetchAllSuppliers',
    async currentPage => {
        const response = await axios.get(`${baseUrl}/api/user/getSupplierList`);
        return response.data.sort((a, b) => a.createdOn - b.createdOn);
    }
);
export const fetchAllSuppliersPageWise = createAsyncThunk(
    'suppliers/fetchAllSuppliersPageWise',
    async currentPage => {
        const response = await axios.get(
            `${baseUrl}/api/user/getSupplierList/${currentPage}`
        );
        return response.data.sort((a, b) => a.createdOn - b.createdOn);
    }
);

export const fetchTotalSupplierCount = createAsyncThunk(
    'data/fetchTotalSupplierCount',
    async userId => {
        const response = await axios.get(
            `${baseUrl}/api/user/countRowsForSupplier`
        );
        if (response.data > 0) {
            return response.data;
        }
    }
);

// export const updateSupplier = createAsyncThunk(
//     'suppliers/updateSupplier',
//     async supplier => {
//         // Replace this with your API call to update a user

//         const response = await fetch(
//             `${baseUrl}/api/user/updateUser/${supplier.id}`,
//             {
//                 method: 'PUT',
//                 body: JSON.stringify(supplier),
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             }
//         );
//         return response.json();
//     }
// );

export const updateSupplier = createAsyncThunk(
    'suppliers/updateSupplier',
    async supplier => {
        try {
            const response = await axios.put(
                `${baseUrl}/api/user/updateUser/${supplier.id}`,
                supplier,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            if (
                error.response &&
                (error.response.status === 400 || error.response.status === 409)
            ) {
                throw new Error(error?.response?.data);
            } else {
                throw new Error(error?.response?.data);
            }
        }
    }
);

// // Create an async thunk for deleting a supplier
export const deleteSupplier = createAsyncThunk(
    'suppliers/deleteSupplier',
    async supplierId => {
        // Replace this with your API call to delete a user
        await fetch(
            `${baseUrl}/api/user/deleteSupplierAndAdmin/${supplierId}`,
            {
                method: 'DELETE'
            }
        );
        return supplierId;
    }
);

const supplierSlice = createSlice({
    name: 'user',
    initialState: {
        suppliers: [],
        orders: [],
        status: 'idle',
        error: null,
        totalRows: 0
    },
    reducers: {},

    extraReducers: builder => {
        builder
            .addCase(getUserData.pending, state => {
                state.status = 'loading';
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchAllSuppliers.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAllSuppliers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.suppliers = action.payload.sort(
                    (a, b) => b.createdOn - a.createdOn
                );
            })
            .addCase(fetchAllSuppliers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchAllSuppliersPageWise.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchAllSuppliersPageWise.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.suppliers = action.payload.sort(
                    (a, b) => b.createdOn - a.createdOn
                );
            })
            .addCase(fetchAllSuppliersPageWise.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(updateSupplier.pending, state => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateSupplier.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Update the user data in the state directly
                const updatedSupplier = action.payload;
                state.suppliers = state.suppliers.map(supplier => {
                    return supplier.id === updatedSupplier.id
                        ? updatedSupplier
                        : supplier;
                });
            })
            .addCase(updateSupplier.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(deleteSupplier.pending, state => {
                state.status = 'loading';
            })
            .addCase(deleteSupplier.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Delete the user from the state directly
                const deletedSupplierId = action.payload;
                state.suppliers = state.suppliers.filter(
                    supplier => supplier.id !== deletedSupplierId
                );
            })
            .addCase(deleteSupplier.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchTotalSupplierCount.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchTotalSupplierCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.totalRows = action.payload;
            })
            .addCase(fetchTotalSupplierCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default supplierSlice.reducer;
