import React, { useRef, useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import baseUrl from '../../slices/baseUrl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { ColorModeContext, tokens } from '../../theme';
import {
    Box,
    CircularProgress,
    Grid,
    Button,
    Container,
    IconButton,
    InputLabel,
    Typography,
    useMediaQuery,
    useTheme,
    Modal,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
// import { fetchFileHeaders } from '../../slices/fetchFileHeadersSlice';
import MessageModal from '../messageModal/MessageModal';
import { clearFileHeaders } from '../../slices/fetchFileHeadersSlice';
import { deleteFile, getUploadedFile } from '../../slices/uploadedFilesSlice';
import { fetchAllSuppliers } from '../../slices/supplierSlice';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const UploadFiles = ({ children }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    let userId = sessionStorage.getItem('userId');
    let firstTime = 0;
    const fileInputRef = useRef();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [supplierId, setSupplierId] = useState(null);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState([]);

    const [isFilePicked, setIsFilePicked] = useState(false);

    const dispatch = useDispatch();

    const data = new FormData();
    const [searchText, setSearchText] = useState('');
    const [showIcon, setShowIcon] = useState(true);

    let clientName = sessionStorage.getItem('clientName');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedSupplierOption, setSelectedSupplierOption] = useState(' ');
    const [isSupplierSelected, setIsSupplierSelected] = useState(false);

    const uploadedFiles = useSelector(
        state => state.uploadedFiles.uploadedFiles
    );
    const allSuppliers = useSelector(state => state.supplier.suppliers);

    // const records = useSelector(state => state.fetchFileHeaders.records);

    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const theme1 = React.useMemo(
        () =>
            createTheme({
                typography: {
                    fontSize: isSmallScreen ? 11 : 14
                },
                button: {
                    fontSize: isSmallScreen ? 11 : 14
                }
            }),
        [isSmallScreen]
    );

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState({});

    useEffect(() => {
        if (selectedSupplierOption !== ' ') {
            dispatch(getUploadedFile(supplierId));
        } else {
            // dispatch(getUploadedFile(userId));
        }
    }, []);

    useEffect(() => {
        dispatch(fetchAllSuppliers());
    }, []);

    const handleSelectSupplier = e => {
        setSelectedSupplierOption(
            prevSupplier => (prevSupplier = e.target.value)
        );
        setSupplierId(e.target.value);
        setIsSupplierSelected(true);
        dispatch(getUploadedFile(e.target.value));
        setIsFilePicked(false);
        setFile(null);
    };

    useEffect(() => {
        setSupplierId(selectedSupplierOption);
    }, [selectedSupplierOption]);

    //--------------------------------------------------------- showMessageHandler
    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    const millisecondToDate = milliseconds => {
        const date = new Date(milliseconds);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        const formattedDateTime = date.toLocaleString(undefined, options);
        return formattedDateTime;
    };

    const onFileChange = e => {
        const selectedFile = e.target.files[0];
        const allowedExtensions = ['.xlsx', '.csv'];
        if (selectedFile) {
            const fileName = selectedFile.name.toLowerCase();
            const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

            if (allowedExtensions.includes(fileExtension)) {
                setFile(selectedFile);
                setName(selectedFile.name);
                setIsFilePicked(true);
            } else {
                setMessage('Please upload file with extension .xlsx or .csv');
                showMessageHandler();
                fileInputRef.current.value = null;
            }
        }
    };

    const uploadFileData = async () => {
        if (!isSupplierSelected && supplierId !== '') {
            setMessage('Please select supplier');
            showMessageHandler();
            return;
        }
        if (!isFilePicked) {
            setMessage('Please select file to upload');
            showMessageHandler();
            return;
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('userId', supplierId);

            dispatch(clearFileHeaders());
            axios
                .post(`${baseUrl}/api/files/fileOperation/header`, formData)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        setMessage('File uploaded successfully');
                        showMessageHandler();
                        setIsFilePicked(false);
                        // dispatch(getUploadedFile(userId));
                        dispatch(getUploadedFile(supplierId));
                    }
                })
                .catch(err => {
                    setMessage(
                        `Oops something went wrong. Error while uploading file: ${err}`
                    );
                    showMessageHandler();
                });
        }
    };

    const handleDrop = e => {
        e.preventDefault();
        const droppedFiles = [...e.dataTransfer.files];
        setFiles(droppedFiles);
        setName(e.dataTransfer.files[0].name);
        setIsFilePicked(true);
    };

    const openFileInput = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleOpen = (supplierId, fileId) => {
        setOpen(true);
        // if (selectedSupplierOption !== ' ') {
        setIdToDelete({ userId: supplierId, fileId });
        // } else {
        //     setIdToDelete({ userId, fileId });
        // }
    };

    const handleClose = () => {
        setOpen(false);
        setIdToDelete({});
    };

    const handleOkDelete = () => {
        dispatch(deleteFile(idToDelete));
        setIdToDelete({});
        handleClose();
        setMessage('File deleted successfully');
        showMessageHandler();
        dispatch(getUploadedFile(supplierId));
    };

    const handleDownload = async userId => {
        if (searchText === '') {
            const fileUrl = `${baseUrl}/api/files/downloadExcel/${userId}`;

            fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const downloadUrl = encodeURI(url);
                    const link = document.createElement('a');
                    link.href = encodeURI(url);
                    link.download = `${uploadedFiles[0].fileName}`;
                    link.click();

                    link.remove();
                    window.URL.revokeObjectURL(downloadUrl);
                })
                .catch(error => {
                    setMessage(error);
                    showMessageHandler();
                });
        }
    };

    const myStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#d0d0d0',
        width: '40vw',
        height: isSmallScreen ? '150px' : '25vh',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        outline: 'none'
    };

    return (
        <Box style={{ padding: '20px' }}>
            <Box
                style={{
                    marginBottom: 10,
                    position: 'relative'
                }}
            >
                {loading && (
                    <div
                        style={{
                            height: '80vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {/* <h2>Uploading...</h2> */}
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    <>
                        <div>
                            <Typography variant="h5">{clientName}</Typography>
                        </div>

                        <InputLabel
                            sx={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                '@media (max-width: 712px)': { fontSize: 15 }
                            }}
                        >
                            Upload File
                        </InputLabel>

                        <Box
                            style={{ border: '1px solid black' }}
                            sx={{ mt: 2, p: 2 }}
                        >
                            <Box
                                width="50%"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        marginRight: '10px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Select supplier
                                </Typography>
                                <FormControl style={{ width: '200px' }}>
                                    <Select
                                        name="selectSupplier"
                                        size="small"
                                        value={selectedSupplierOption}
                                        onChange={e => handleSelectSupplier(e)}
                                    >
                                        <MenuItem
                                            value=" "
                                            disabled
                                            sx={{ fontSize: 12 }}
                                        >
                                            Select
                                        </MenuItem>
                                        {allSuppliers.map(supplier => (
                                            <MenuItem
                                                key={supplier.id}
                                                value={supplier.id}
                                            >
                                                {supplier.firstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Grid
                                style={{
                                    border: '1px dotted gray',
                                    position: 'relative'
                                }}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mb: '15px',
                                    mt: '10px'
                                }}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <Grid
                                    textAlign="center"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <Button onClick={openFileInput}>
                                        <OpenInBrowserIcon
                                            style={{
                                                color: 'gray',
                                                fontSize: '40px'
                                            }}
                                        />
                                        <input
                                            type="file"
                                            hidden
                                            ref={fileInputRef}
                                            // onChange={onFileChange}
                                            accept=".txt,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                        />
                                    </Button>

                                    <Container
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                margin: '0px'
                                            }}
                                        >
                                            {files.length === 0 ? (
                                                <Typography variant="body2">
                                                    Drag and drop files here
                                                </Typography>
                                            ) : (
                                                <>
                                                    <ul
                                                        style={{
                                                            margin: '0',
                                                            padding: '0',
                                                            listStyleType:
                                                                'none'
                                                        }}
                                                    >
                                                        <li>
                                                            {' '}
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    margin: '0',
                                                                    padding: '0'
                                                                }}
                                                            >
                                                                {' '}
                                                                {files[0].name}
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                padding: '0px',
                                                margin: '0px'
                                            }}
                                        >
                                            or
                                        </p>
                                        <Button
                                            variant=""
                                            component="label"
                                            sx={{
                                                fontWeight: 'bold',
                                                width: '30px',
                                                textDecoration: 'underline',
                                                textDecorationThickness:
                                                    '1.8px',
                                                textUnderlineOffset: '7px'
                                            }}
                                        >
                                            Browse
                                            {/* <input
                                        type="file"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={onFileChange}
                                        accept=".txt,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    /> */}
                                            <input
                                                type="file"
                                                hidden
                                                // onChange={e =>
                                                //     setFile(e.target.files[0])
                                                // }
                                                ref={fileInputRef}
                                                onChange={onFileChange}
                                            />
                                        </Button>
                                        {!isFilePicked && (
                                            <Typography variant="body2">
                                                Select File
                                            </Typography>
                                        )}
                                        {isFilePicked && (
                                            <Typography>&nbsp;</Typography>
                                        )}
                                    </Container>
                                </Grid>
                                <Box
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        bottom: '0px'
                                    }}
                                >
                                    {isFilePicked && (
                                        <div align="right">
                                            <span
                                                style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                Filename :
                                            </span>{' '}
                                            <span style={{ fontSize: '10px' }}>
                                                {file.name}
                                            </span>
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                </Box>
                            </Grid>

                            <Typography style={{ fontSize: '12px' }}>
                                * Kindly upload .xlsx or .csv file only
                            </Typography>

                            <Box
                                style={{
                                    marginTop: '10px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    fontSize: '10px'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    name="brokenSubmit"
                                    sx={{
                                        width: '150px',
                                        boxShadow: 'none',
                                        textTransform: 'none',
                                        fontWeight: isFilePicked
                                            ? 'bold'
                                            : 'normal',
                                        backgroundColor: isFilePicked
                                            ? '#4CCEAC'
                                            : '#555555',
                                        color: isFilePicked
                                            ? '#000000'
                                            : '#ffffff',
                                        '&:hover': {
                                            bgcolor: isFilePicked
                                                ? 'rgb(76,170,150)'
                                                : '#111111',
                                            color: isFilePicked
                                                ? '#000000'
                                                : '#ffffff'
                                        }
                                    }}
                                    disabled={
                                        !isSupplierSelected || !isFilePicked
                                    }
                                    onClick={uploadFileData}
                                >
                                    Upload
                                </Button>
                                <Tooltip title="Next">
                                    <span>
                                        <Button
                                            sx={{
                                                width: '50px',
                                                backgroundColor:
                                                    !isSupplierSelected ||
                                                    isFilePicked ||
                                                    uploadedFiles.length === 0
                                                        ? '#999999'
                                                        : '#4CCEAC',
                                                color:
                                                    !isSupplierSelected ||
                                                    isFilePicked ||
                                                    uploadedFiles.length === 0
                                                        ? '#ffffff'
                                                        : '#000000',
                                                marginLeft: '25px',
                                                '&:hover': {
                                                    bgcolor: '#111111',
                                                    color: '#ffffff'
                                                }
                                            }}
                                            onClick={e => {
                                                navigate(`/headermapping`);
                                            }}
                                            disabled={
                                                !isSupplierSelected ||
                                                isFilePicked ||
                                                uploadedFiles.length === 0
                                            }
                                        >
                                            &gt;&gt;
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                            {isSupplierSelected &&
                                Array.isArray(uploadedFiles) &&
                                uploadedFiles?.length > 0 &&
                                uploadedFiles.map(file => (
                                    <Box
                                        key={file.id}
                                        sx={{
                                            m: 1,
                                            mt: 3,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            lineheight: '5px'
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                backgroundColor:
                                                    colors.grey[800],
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                // border: '1px solid gray',
                                                paddingBottom: '15px',
                                                margin: 0,
                                                borderBottom: 0,
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        <strong>
                                                            File Name
                                                        </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        <strong>
                                                            Uploaded Date
                                                        </strong>
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={2}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        paddingRight: '16px'
                                                    }}
                                                >
                                                    <Typography>
                                                        <strong>Action</strong>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                                backgroundColor:
                                                    colors.primary[400],
                                                borderBottomLeftRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                // border: '1px solid gray',
                                                paddingBottom: '10px',
                                                margin: 0,
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Grid item xs={5}>
                                                <Typography>
                                                    {file.fileName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>
                                                    {millisecondToDate(
                                                        file.createdOn
                                                    )}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                    paddingLeft: '0px',
                                                    paddingTop: '8px'
                                                }}
                                            >
                                                {/* <Tooltip title="Download">
                                                    <IconButton
                                                        aria-label="download"
                                                        onClick={() =>
                                                            handleDownload(
                                                                supplierId
                                                            )
                                                        }
                                                    >
                                                        <DownloadIcon
                                                            sx={{
                                                                color: 'rgba(0,0,255,0.5)'
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip> */}
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        aria-label="delete"
                                                        sx={{
                                                            marginRight: '5px',
                                                            '&:hover': {
                                                                color: 'red'
                                                            }
                                                        }}
                                                        onClick={e =>
                                                            handleOpen(
                                                                supplierId,
                                                                file.id
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                        </Box>
                    </>
                )}
                {showMessage && (
                    <MessageModal
                        message={message}
                        setShowMessage={setShowMessage}
                    />
                )}
            </Box>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={myStyle}>
                        <Typography
                            id="modal-modal-title"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                            variant="body1"
                            component="h2"
                        >
                            Are you sure to delete this file?
                        </Typography>
                        <Box
                            style={{
                                width: isSmallScreen ? '100%' : '70%',
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginTop: '20px'
                            }}
                        >
                            <Button
                                style={{
                                    width: '105px',
                                    backgroundColor: '#555555',
                                    borderRadius: '5px',
                                    border: '1px solid #555555',
                                    boxShadow: 'none'
                                }}
                                onClick={e => handleOkDelete()}
                                type="submit"
                                variant="contained"
                            >
                                Ok
                            </Button>{' '}
                            <Button
                                type="cancel"
                                onClick={handleClose}
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    width: '100px',
                                    color: '#555555',
                                    backgroundColor: 'inherit',
                                    border: '1px solid #555555',
                                    borderRadius: '5px'
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </Box>
    );
};
export default UploadFiles;
