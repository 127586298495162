import React, { useState, useEffect, useRef } from 'react';
import baseUrl from '../../slices/baseUrl';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
    useMediaQuery,
    MenuItem,
    TextField,
    Box,
    Grid,
    InputAdornment,
    IconButton,
    Tooltip,
    Button,
    useTheme,
    Typography,
    Select,
    FormControl,
    InputLabel,
    CircularProgress
} from '@mui/material';
import MessageModal from '../messageModal/MessageModal';
import { ColorModeContext, tokens } from '../../theme';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
    fetchAllRecords,
    fetchTotalRows,
    fetchSearchedRows,
    fetchSortedRecords,
    sendMail,
    resetSendMail
} from '../../slices/fileDataSlice';
import DownloadIcon from '@mui/icons-material/Download';
import { fetchAllSuppliers } from '../../slices/userSlice';
import { getUploadedFile } from '../../slices/uploadedFilesSlice';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const DynamicTable = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);
    const [isSupplierSelected, setIsSupplierSelected] = useState(false);

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery('(max-width:850px)');
    const userId = sessionStorage.getItem('userId');
    const totalRows = useSelector(state => state.fileData.totalRows);
    const statusResult = useSelector(state => state.fileData.status);
    let searchResult = useSelector(state => state.fileData.search);

    const records = useSelector(state => state.fileData.records);
    const [sortOrder, setSortOrder] = useState('asc');
    const uploadedFiles = useSelector(
        state => state.uploadedFiles.uploadedFiles
    );
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showIcon, setShowIcon] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [inputPage, setInputPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [supplierId, setSupplierId] = useState(null);
    const [selectedSupplierOption, setSelectedSupplierOption] = useState(' ');
    const allSuppliers = useSelector(state => state.user.suppliers);
    let mailSentStatus = useSelector(state => state.fileData.mailSentStatus);
    const textInputRef = useRef(null);

    const [dataToMail, setDataToMail] = useState({
        dataIdToMail: '',
        userIdToMail: '',
        supplierToMail: '',
        productNameToMail: '',
        UPCToMail: '',
        priceToMail: '',
        quantityToMail: ''
    });

    useEffect(() => {
        dispatch(fetchAllRecords(currentPage));
        dispatch(fetchTotalRows());
        mailSentStatus = null;
        searchResult = [1];
    }, []);

    useEffect(() => {
        dispatch(fetchAllRecords(currentPage));
        dispatch(fetchTotalRows());
    }, [currentPage]);

    useEffect(() => {
        const fetchData = async () => {
            let newRecords = [];
            setLoading(true);
            if (records.length > 0) {
                for (let index = 0; index < records.length; index++) {
                    const record = records[index];
                    try {
                        const details = await getNameAndEmail(record.userId);
                        newRecords.push({
                            dataId: record.dataId,
                            userId: record.userId,
                            productName: record.productName,
                            upc: record.upc,
                            price: record.price,
                            quantity: record.quantity,
                            userName: details.firstName,
                            organizationName: details.organizationName
                        });
                    } catch (error) {
                        console.error(error);
                    }

                    if (index === records.length - 1) {
                        setData(newRecords);
                        setLoading(false);
                    }
                }
            }
            if (records.length <= 0) {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            setLoading(false);
        };
    }, [records]);

    async function getNameAndEmail(userId) {
        const response = await fetch(
            `${baseUrl}/api/user/getDataByUserId/${userId}`
        );

        if (!response.ok) {
            throw new Error(`Error fetching user data for userId ${userId}`);
        }

        const data = await response.json();
        return {
            firstName: data.firstName,
            organizationName: data.organisationName
        }; // Include the return statement
    }

    // ... rest of your component

    useEffect(() => {
        if (totalRows > 1) {
            setTotalPages(prevPages => (prevPages = Math.ceil(totalRows / 20)));
        }
    }, [totalRows]);

    useEffect(() => {
        if (mailSentStatus !== null) {
            setMessage(mailSentStatus);
            showMessageHandler();
        }
        dispatch(resetSendMail());
    }, [mailSentStatus]);

    useEffect(() => {
        if (searchText === '') {
            dispatch(
                fetchSortedRecords({
                    sortOrder,
                    currentPage
                })
            );
        } else {
        }
    }, [searchText, sortOrder]);

    useEffect(() => {
        const fetchData = async () => {
            if (searchText !== '') {
                let newRecords = [];
                setLoading(true);
                if (searchResult.length > 0) {
                    for (let index = 0; index < searchResult.length; index++) {
                        const record = searchResult[index];
                        try {
                            const details = await getNameAndEmail(
                                record.userId
                            );

                            newRecords.push({
                                dataId: record.dataId,
                                userId: record.userId,
                                productName: record.productName,
                                upc: record.upc,
                                price: record.price,
                                quantity: record.quantity,
                                userName: details.firstName,
                                organizationName: details.organizationName
                            });
                        } catch (error) {
                            console.error(error);
                        }

                        if (index === searchResult.length - 1) {
                            setData(newRecords);
                            setLoading(false);
                        }
                    }
                }
            } else {
                setData([]);
            }
        };
        if (searchResult.length > 0) {
            fetchData();
        } else {
            setMessage('No data found');
            showMessageHandler();
        }
    }, [searchResult]);

    const menuStyle = {
        fontSize: '12px'
    };

    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });

    const handleSort = async sortOrder => {
        setSortOrder(
            prevSortOrder =>
                (prevSortOrder = prevSortOrder === 'desc' ? 'asc' : 'desc')
        );
    };

    const handleChangeSearchText = e => {
        if (e.target.value === '') {
            setShowIcon(true);
        } else {
            setShowIcon(true);
        }
        setSearchText(e.target.value);
    };

    const handleSearch = () => {
        if (searchText === '') {
            setMessage('Enter something to search');
            showMessageHandler();
            return;
        }
        setShowIcon(false);
        dispatch(fetchSearchedRows(searchText));

        textInputRef.current.blur();
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleClearSearch = () => {
        setData(prevState => (prevState = []));
        dispatch(fetchAllRecords(currentPage));
        dispatch(fetchTotalRows());
        setSearchText('');
        setShowIcon(true);
        textInputRef.current.blur();
    };

    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    const handleBlur = e => {
        if (searchText.length <= 0) {
            setShowIcon(true);
        }
        setSearchText(e.target.value);
    };

    const handleClickOpen = item => {
        setDataToMail({
            dataIdToMail: item?.dataId,
            userIdToMail: item?.userId,
            userNameToMail: item?.userName,
            productNameToMail: item?.productName,
            UPCToMail: item?.upc,
            priceToMail: item?.price,
            quantityToMail: item?.quantity
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOrderChange = e => {
        const { name, value } = e.target;
        setDataToMail({
            ...dataToMail,
            [name]: value
        });
    };

    const handleSendMail = dataToMail => {
        setMailLoading(prevState => (prevState = true));
        const obj = {
            userId: dataToMail.userIdToMail ? dataToMail.userIdToMail : null,
            upc: dataToMail.UPCToMail ? dataToMail.UPCToMail : null,
            price: dataToMail.priceToMail ? dataToMail.priceToMail : null,
            orderQty: dataToMail.quantityToMail
                ? dataToMail.quantityToMail
                : null,
            productName: dataToMail.productNameToMail
                ? dataToMail.productNameToMail
                : null
        };
        dispatch(sendMail(obj)).then(result => {
            if (result.meta.requestStatus === 'fulfilled') {
                setMailLoading(prevState => (prevState = false));
                setOpen(false);
            }
        });
    };

    const goToPage = () => {
        let newPage = Number(inputPage);

        if (newPage < 1) {
            setMessage('The minimum page value is 1');
            showMessageHandler();
        } else if (newPage > totalPages) {
            setMessage('The value exceeds the maximum page count');
            showMessageHandler();
        } else {
            setCurrentPage(prevPage => (prevPage = newPage));
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(prevPage => (prevPage = newPage));
        setInputPage(newPage);
    };

    const handleDownload = async => {
        setDownloading(true);
        const currentDate = new Date();

        // Get current date
        const dateString = currentDate.toISOString().split('T')[0];

        // Get current time in 12-hour format
        let hours = currentDate.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert hours to 12-hour format
        const minutes = currentDate.getMinutes();
        const timeString = `${hours}-${minutes}-${ampm}`;
        const fileUrl = `${baseUrl}/api/files/downloadDataInExcel`;

        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const downloadUrl = encodeURI(url);
                const link = document.createElement('a');
                link.href = encodeURI(url);
                link.download = `BeautyBlush_${dateString}_${timeString}.xlsx`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(downloadUrl);
                setDownloading(false);
                setMessage('File downloaded successfully');
                showMessageHandler();
            })
            .catch(error => {
                setMessage(error);
                showMessageHandler();
                setDownloading(false);
            });
    };

    return (
        <div
            style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            {loading && (
                <div
                    style={{
                        height: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h2>Wait while fetching data...</h2>
                    <CircularProgress />
                </div>
            )}
            {downloading && (
                <div
                    style={{
                        height: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h2>Downloading in progress...</h2>
                    <CircularProgress />
                </div>
            )}

            {mailLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: '45%',
                        left: '45%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 100
                    }}
                >
                    <CircularProgress />
                </div>
            )}

            {!loading && !downloading && !mailLoading && (
                <>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box
                            width="50%"
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <InputLabel
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    '@media (max-width: 712px)': {
                                        fontSize: 15
                                    }
                                }}
                            >
                                Data
                            </InputLabel>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Box
                                sx={{
                                    mr: '20px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Tooltip title="Download">
                                    <IconButton
                                        aria-label="download"
                                        onClick={() => handleDownload()}
                                    >
                                        <DownloadIcon
                                            sx={{
                                                '&:hover': {
                                                    color: '#6870fa'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box sx={{ mr: '20px' }}>
                                <TextField
                                    sx={{
                                        width: isSmallScreen
                                            ? '150px'
                                            : '200px',

                                        '& .MuiInputBase-root': {
                                            fontSize: '13px',
                                            height: '36px'
                                        }
                                    }}
                                    size="small"
                                    value={searchText}
                                    placeholder="Search..."
                                    inputRef={textInputRef}
                                    onChange={handleChangeSearchText}
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    border=" 1px solid black"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                onClick={
                                                    showIcon
                                                        ? handleSearch
                                                        : handleClearSearch
                                                }
                                                sx={{
                                                    cursor: 'pointer'
                                                }}
                                                disabled={
                                                    searchText.length <= 0
                                                }
                                            >
                                                {showIcon ? (
                                                    <SearchIcon
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                ) : (
                                                    <ClearIcon
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                )}
                                            </InputAdornment>
                                        )
                                    }}
                                    onKeyDown={handleKeyPress}
                                />
                            </Box>

                            <Box width={isSmallScreen ? '150px' : '200px'}>
                                <TextField
                                    label="Sorting"
                                    select
                                    value={sortOrder}
                                    open={true}
                                    size="small"
                                    //
                                    fullWidth
                                    color="success"
                                    InputProps={{
                                        style: {
                                            fontSize: '12px',
                                            paddingTop: '1px',
                                            paddingBottom: '1px'
                                        }
                                    }}
                                    onChange={e => handleSort(e.target.value)}
                                >
                                    <MenuItem value={'desc'} style={menuStyle}>
                                        Sort Descending
                                    </MenuItem>
                                    <MenuItem value={'asc'} style={menuStyle}>
                                        Sort Ascending
                                    </MenuItem>
                                </TextField>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            lineheight: '5px'
                        }}
                    >
                        <Grid
                            container
                            className="grid-table-header"
                            style={{
                                backgroundColor: colors.grey[800],
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                                border: `0.5px solid ${colors.grey[800]}`,
                                outline: 'none',
                                paddingTop: '10px',
                                paddingBottom: '10px'
                            }}
                            sx={{
                                '& .MuiGrid-item': {
                                    padding: '6px',
                                    fontSize: '10px'
                                }
                            }}
                        >
                            <Grid item xs={1}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    ID
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Product Name
                                </Typography>
                            </Grid>
                            <Grid item xs={1.8}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    UPC
                                </Typography>
                            </Grid>
                            <Grid item xs={0.8}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    Price ($)
                                </Typography>
                            </Grid>
                            <Grid item xs={0.1}></Grid>
                            <Grid item xs={0.8}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}
                                >
                                    Quantity
                                </Typography>
                            </Grid>
                            <Grid item xs={0.1}></Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Organization Name
                                </Typography>
                            </Grid>
                            <Grid item xs={0.7}>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Order
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            lineheight: '5px'
                        }}
                        // style={{
                        //     border: '1px solid grey',
                        //     borderBottomLeftRadius: '2px',
                        //     borderBottomRightRadius: '2px'
                        // }}
                    >
                        <Box sx={{ width: '100%' }}>
                            {data.map((item, index) => (
                                <div key={item?.dataId}>
                                    <Grid
                                        container
                                        className="grid-table-container"
                                        style={{
                                            borderBottomLeftRadius:
                                                index === data.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            borderBottomRightRadius:
                                                index === data.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            border: `0.5px solid ${colors.grey[800]}`,
                                            borderCollapse: 'collapse',
                                            outline: 'none',
                                            fontSize: '0.75rem',
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? 'inherit'
                                                    : colors.primary[400]
                                        }}
                                        sx={{
                                            '& .MuiGrid-item': {
                                                padding: '6px'
                                            }
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <Typography
                                                style={{
                                                    // paddingLeft: '5px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.dataId}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.8}>
                                            <Typography
                                                style={{
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.upc}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.8}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {item?.price}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.1}></Grid>
                                        <Grid item xs={0.8}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {item?.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.1}></Grid>
                                        <Grid item xs={2.5}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {' '}
                                                {item?.organizationName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={0.7}
                                            style={{
                                                padding: '0px'
                                            }}
                                        >
                                            <Tooltip
                                                title="Send mail"
                                                placement="right"
                                            >
                                                <IconButton
                                                    aria-label="edit"
                                                    // onClick={e =>
                                                    //     handleSendMail(
                                                    //         item.dataId,
                                                    //         item.userId
                                                    //     )
                                                    // }
                                                    sx={{
                                                        '&:hover': {
                                                            color: '#6870fa'
                                                        }
                                                    }}
                                                    onClick={e =>
                                                        handleClickOpen(item)
                                                    }
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                                {/* <DialogComponent
                                            open={open}
                                            handleClose={handleClose}
                                            handleSendMail={handleSendMail}
                                        /> */}
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </Box>{' '}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            fontSize: '10px'
                        }}
                    >
                        <Tooltip title="Previous">
                            <Button
                                sx={{
                                    width: '50px',
                                    bgcolor: '#555555',
                                    marginTop: '15px',
                                    color: '#ffffff',
                                    '&:hover': {
                                        bgcolor: '#111111',
                                        color: '#ffffff'
                                    }
                                }}
                                onClick={e => {
                                    navigate(`/headermapping`);
                                }}
                            >
                                &lt;&lt;
                            </Button>
                        </Tooltip>
                    </Box>
                    {/* <Box style={{ marginTop: '10px' }}></Box> */}
                    <Grid
                        container
                        style={{
                            display: 'flex',
                            overflow: 'hidden',
                            // width: '94vw',
                            position: 'sticky',
                            bottom: '-17px',
                            left: '-10px',
                            right: 0,
                            margin: '20px 0 -30px 0px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: colors.primary[400],
                            padding: '7px',
                            zIndex: 11
                        }}
                    >
                        <Stack
                            spacing={2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Pagination
                                count={totalPages}
                                shape="rounded"
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                            <Box
                                style={{
                                    marginLeft: '10px',
                                    marginTop: '0px'
                                }}
                            >
                                <span>| Go to page : &nbsp;</span>
                                <input
                                    type="number"
                                    value={inputPage === 0 ? '' : inputPage}
                                    // onChange={e => {
                                    //     let newPage = e.target.value
                                    //         ? Math.min(
                                    //               Math.max(Number(e.target.value), 1),
                                    //               totalPages
                                    //           )
                                    //         : 1;
                                    // }}
                                    onChange={e =>
                                        setInputPage(
                                            prevPage =>
                                                (prevPage = Number(
                                                    e.target.value
                                                ))
                                        )
                                    }
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            goToPage();
                                            e.target.blur(); // Remove focus from the input field
                                        }
                                    }}
                                    style={{
                                        width: '50px',
                                        height: '30px',
                                        backgroundColor: 'inherit',
                                        border: '0.5px solid grey',
                                        borderRadius: '3px',
                                        color: colors.primary[100]
                                    }}
                                    min="1"
                                    max={totalPages}
                                />
                                <Button
                                    sx={{
                                        marginLeft: '10px',
                                        backgroundColor: '#555555',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#666666'
                                        },
                                        width: '40px'
                                    }}
                                    onClick={goToPage}
                                >
                                    Go
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>

                    {open && (
                        <Dialog
                            open={open}
                            // TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            PaperProps={{
                                component: 'form',
                                onSubmit: event => {
                                    event.preventDefault();
                                    const formData = new FormData(
                                        event.currentTarget
                                    );
                                    const formJson = Object.fromEntries(
                                        formData.entries()
                                    );
                                    // handleClose();
                                }
                            }}
                            style={{
                                padding: '10px 20px',
                                position: 'absolute'
                            }}
                        >
                            <DialogTitle sx={{ fontSize: '18px' }}>
                                <strong>Send mail</strong>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    style={{ textAlign: 'center' }}
                                >
                                    Hello, {dataToMail.userNameToMail}
                                </DialogContentText>
                                <DialogContentText
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    I want to buy this product
                                </DialogContentText>
                                <hr
                                    style={{
                                        padding: '0px',
                                        margin: '10px 0px'
                                    }}
                                />
                                <Grid container>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Product name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            value={
                                                dataToMail?.productNameToMail
                                            }
                                            autoFocus
                                            size="small"
                                            margin="dense"
                                            id="name"
                                            name="productNameToMail"
                                            // label="Product name"
                                            type="text"
                                            variant="outlined"
                                            onChange={handleOrderChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            UPC
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            value={dataToMail?.UPCToMail}
                                            autoFocus
                                            size="small"
                                            margin="dense"
                                            id="name"
                                            name="UPCToMail"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleOrderChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Price
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            value={dataToMail?.priceToMail}
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            name="priceToMail"
                                            size="small"
                                            // type="number"
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleOrderChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Order quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            value={dataToMail?.quantityToMail}
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            size="small"
                                            name="quantityToMail"
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleOrderChange}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    sx={{
                                        textTransform: 'none',
                                        marginRight: '10px',
                                        backgroundColor: 'rgb(200,50,50)',
                                        '&:hover': {
                                            backgroundColor: 'rgb(139,0,0)'
                                        }
                                    }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    onClick={e => handleSendMail(dataToMail)}
                                    sx={{
                                        textTransform: 'none'
                                        // backgroundColor: 'rgb(50,250,50)'
                                    }}
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Send
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}
            {showMessage && (
                <MessageModal
                    message={message}
                    setShowMessage={setShowMessage}
                />
            )}
        </div>
    );
};

export default DynamicTable;
