import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import baseUrl from '../../slices/baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { tokens } from '../../theme';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import './style.css';
import { grey } from '@mui/material/colors';

import {
    fetchFileHeaders,
    fetchMappedHeaders,
    deleteMapping
} from '../../slices/fetchFileHeadersSlice';
import {
    Box,
    Grid,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Typography,
    Select,
    useMediaQuery,
    useTheme,
    CircularProgress,
    Modal
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageModal from '../messageModal/MessageModal';
import { useNavigate } from 'react-router-dom';
import { fetchAllSuppliers } from '../../slices/supplierSlice';

const HeaderMapping = ({ children }) => {
    const userId = sessionStorage.getItem('userId');
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editingHeaderId, setEditingHeaderId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isHeaderMapped, setIsHeaderMapped] = useState(false);
    const [fileHeaderSelected, setFileHeaderSelected] = useState(false);
    const [userHeaderSelected, setUserHeaderSelected] = useState(false);
    const colors = tokens(theme.palette.mode);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [fileHeaderName, setFileHeaderName] = useState('');
    const [userHeaderName, setUserHeaderName] = useState('');
    const [isSupplierSelected, setIsSupplierSelected] = useState(false);

    // const [fileReadSuccessful, setFileReadSuccessful] = useState(false);
    const [selectedFileHeader, setSelectedFileHeader] = useState({
        id: '',
        name: ''
    });
    const [listData, setListData] = useState('');
    const [headerNameCount, setHeaderNameCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState({});
    const [sendData, setSendData] = useState({
        fileHeader: '',
        id: '',
        headerName: ''
    });
    const allSuppliers = useSelector(state => state.supplier.suppliers);

    const headerNames = useSelector(
        state => state.fetchFileHeaders.headerNames
    );
    const recordsResult = useSelector(state => state.fetchFileHeaders.records);

    const [newHeader, setNewHeader] = useState({
        headerName: ''
    });

    const [selectedSupplierOption, setSelectedSupplierOption] = useState(' ');
    const [supplierId, setSupplierId] = useState(null);

    useEffect(() => {
        dispatch(fetchAllSuppliers());
    }, []);

    useEffect(() => {
        if (selectedSupplierOption !== ' ') {
            dispatch(fetchFileHeaders(supplierId));
        } else {
            // dispatch(fetchFileHeaders(userId));
        }
    }, [dispatch, userId, supplierId, selectedSupplierOption]);

    const data = new FormData();
    const UserHeaderDropdown = [
        'Product Name',
        // 'Item Name',
        // 'Item',
        // 'Product',
        // 'Product#',
        // 'Item Description',
        // 'Description',
        // 'Details',
        // 'Specification',
        // 'Dept',
        // 'Department',
        // 'Section',
        // 'Division',
        // 'Branch',
        // 'Compartment',
        // 'Segment',
        // 'Sector',
        'UPC',
        // 'Casepack',
        // 'Selling Price',
        // 'Cost',
        'Price',
        // 'Avail',
        // 'Availability',
        // 'Accessibility',
        // 'Brand',
        // 'Brand name',
        // 'Brand name fragrances',
        'Quantity'
        // 'Qty',
        // 'Order qty',
        // 'Qty order',
        // 'Stock'
        // 'Type',
        // 'Kind',
        // 'Variety',
        // 'Order',
        // 'Total',
        // 'Design',
        // 'Designer',
        // 'Hyperlink',
        // 'Link'
    ];

    useEffect(() => {
        if (recordsResult?.length > 0) {
            setListData(recordsResult);
        }
        return () => {
            setListData('');
        };
    }, [recordsResult]);

    useEffect(() => {
        let headerNameCount = 0;
        if (listData.length > 0) {
            for (let item of listData) {
                if (item.headerName !== null) {
                    headerNameCount++;
                }
            }

            setHeaderNameCount(prevCount => (prevCount = headerNameCount));
        }
    }, [listData]);

    const handleSelectSupplier = e => {
        setSelectedSupplierOption(
            prevSupplier => (prevSupplier = e.target.value)
        );
        setSupplierId(e.target.value);
        setIsSupplierSelected(prevSupplier => (prevSupplier = true));
        dispatch(fetchMappedHeaders(e.target.value));
        // dispatch(getUploadedFile(e.target.value));
    };

    useEffect(() => {
        setSupplierId(selectedSupplierOption);
    }, [selectedSupplierOption]);

    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const theme2 = createTheme({
        typography: {
            fontSize: 11
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        components: {
            MuiGrid: {
                styleOverrides: {
                    root: {
                        padding: '5px 5px'
                    }
                }
            }
        }
    });
    const theme1 = React.useMemo(
        () =>
            createTheme({
                typography: {
                    fontSize: isSmallScreen ? 11 : 14
                },
                button: {
                    fontSize: isSmallScreen ? 11 : 14
                }
            }),
        [isSmallScreen]
    );

    const handleHeaderAndId = e => {
        const selectedHeader = headerNames.find(
            header => header.fileHeader === e.target.value
        );
        if (selectedHeader) {
            setFileHeaderName(selectedHeader.fileHeader);
            setFileHeaderSelected(true);
            setSelectedFileHeader({
                id: selectedHeader.id,
                name: selectedHeader.fileHeader
            });
        }
    };

    const handleDropdownChange = event => {
        setUserHeaderName(event.target.value);
        setUserHeaderSelected(true);
    };

    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    const handleSubmit = async e => {
        if (!isSupplierSelected && supplierId !== '') {
            setMessage('Please select supplier');
            showMessageHandler();
            return;
        }
        if (!fileHeaderSelected) {
            setMessage('Please select File header');
            showMessageHandler();
        } else if (!userHeaderSelected) {
            setMessage('Please select User header');
            showMessageHandler();
        } else {
            setLoading(true);
            e.preventDefault();
            // const updatedUserId =
            //     selectedSupplierOption !== ' ' ? +supplierId : +userId;
            const sendSheetData = {
                id: selectedFileHeader.id,
                userId: +supplierId,
                fileHeader: selectedFileHeader.name,
                headerName: userHeaderName
            };

            axios
                .put(
                    `${baseUrl}/api/files/fileOperation/headerMapping`,
                    sendSheetData
                )
                .then(response => {
                    if (response.status === 200) {
                        setLoading(false);
                        // setListData(prevData => [...prevData, response.data]);
                        // if (selectedSupplierOption !== ' ') {
                        dispatch(fetchMappedHeaders(supplierId));
                        // } else {
                        //     dispatch(fetchMappedHeaders(userId));
                        // }
                        setMessage('Submitted successfully');
                        setIsHeaderMapped(true);
                        showMessageHandler();
                        setUserHeaderName('');
                        setFileHeaderName('');
                        setFileHeaderSelected(false);
                        setUserHeaderSelected(false);
                    }
                })
                .catch(colMapError => {
                    setMessage(colMapError);
                    showMessageHandler();
                });
        }
    };

    const handleReadData = async () => {
        if (!isSupplierSelected && supplierId !== '') {
            setMessage('Please select supplier');
            showMessageHandler();
            return;
        } else {
            setLoading(true);
            // if (selectedSupplierOption !== ' ') {
            await axios
                .post(`${baseUrl}/api/files/fileOperation?userId=${supplierId}`)
                .then(response => {
                    if (response.status === 200) {
                        setLoading(false);
                        setMessage('File read successfully');
                        showMessageHandler();
                        setIsHeaderMapped(false);
                    }
                })
                .catch(colMapError => {
                    setMessage(colMapError);
                    showMessageHandler();
                });
        }
        // } else {
        //     await axios
        //         .post(`${baseUrl}/api/files/fileOperation?userId=${userId}`)
        //         .then(response => {
        //             if (response.status === 200) {
        //                 setLoading(false);
        //                 setMessage('File read successfully');
        //                 showMessageHandler();
        //             }
        //         })
        //         .catch(colMapError => {
        //             setMessage(
        //                 `'Error occurred while making the POST request: ${colMapError}`
        //             );
        //             showMessageHandler();
        //         });
        // }
    };

    // const handleOpen = (userId, id) => {
    //     setOpen(true);
    //     setIdToDelete({ userId, id });
    // };
    // const handleClose = () => {
    //     setOpen(false);
    //     setIdToDelete({});
    // };

    // const handleOkDelete = () => {
    //     dispatch(deleteMapping(idToDelete));
    //     setIdToDelete({});
    //     handleClose();
    // };

    const handleEditClick = listItem => {
        setSendData({
            ...listItem,
            fileHeader: listItem.fileHeader,
            id: listItem.id
        });

        setEditingHeaderId(listItem.id);
        setNewHeader(listItem);
    };

    const handleCancelEdit = () => {
        setEditingHeaderId(null);
        setNewHeader({ headerName: '' });
    };

    const handleHeaderNameEdit = e => {
        setSendData(prevState => ({
            ...prevState,
            headerName: e.target.value
        }));
        setNewHeader(prevState => ({
            ...prevState,
            headerName: e.target.value
        }));
    };

    const handleSaveEdit = newHeader => {
        axios
            .put(`${baseUrl}/api/files/fileOperation/headerMapping`, sendData)
            .then(response => {
                if (response.status === 200) {
                    // if (selectedSupplierOption !== ' ') {
                    dispatch(fetchMappedHeaders(supplierId));
                    // } else {
                    //     dispatch(fetchMappedHeaders(userId));
                    // }
                    setMessage(`${newHeader.headerName} updated successfully`);
                    showMessageHandler();
                    setIsHeaderMapped(true);
                    setEditingHeaderId(null);
                    setNewHeader({});
                }
            })
            .catch(colMapError => {
                setMessage(colMapError);
                showMessageHandler();
            });
    };

    const myStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#d0d0d0',
        width: '40vw',
        height: isSmallScreen ? '150px' : '25vh',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        outline: 'none'
    };

    return (
        <Box style={{ padding: '20px' }}>
            <Box
                style={{
                    marginBottom: 10
                }}
            >
                {loading && (
                    <div
                        style={{
                            height: '80vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {/* <h2>Reading...</h2> */}
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    <>
                        <InputLabel
                            sx={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                '@media (max-width: 712px)': { fontSize: 15 }
                            }}
                        >
                            Header Mapping
                        </InputLabel>

                        {/* ----------------------------------------- Main box 400 px height */}
                        <Grid
                            style={{ border: '1px solid black' }}
                            sx={{ mt: 2, p: 2 }}
                        >
                            <Box
                                width="50%"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        marginRight: '10px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Select supplier
                                </Typography>
                                <FormControl style={{ width: '200px' }}>
                                    <Select
                                        name="selectSupplier"
                                        size="small"
                                        value={selectedSupplierOption}
                                        onChange={e => handleSelectSupplier(e)}
                                    >
                                        <MenuItem
                                            value=" "
                                            disabled
                                            sx={{ fontSize: 12 }}
                                        >
                                            Select
                                        </MenuItem>
                                        {allSuppliers.map(supplier => (
                                            <MenuItem
                                                key={supplier.id}
                                                value={supplier.id}
                                            >
                                                {supplier.firstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Grid
                                style={{
                                    border: '1px dotted gray',
                                    position: 'relative'
                                }}
                                sx={{
                                    p: '10px',
                                    display: 'flex',
                                    mt: '10px'
                                }}
                            >
                                <div className="cmtSecondRow">
                                    <div className="cmtSheetName">
                                        <Typography variant="subtitle1">
                                            File Headers
                                        </Typography>
                                        <div className="sheetNameDropDown">
                                            <FormControl className="clientSheetNameDropDown">
                                                <Select
                                                    style={{
                                                        width: '200px'
                                                    }}
                                                    size="small"
                                                    name="userHeader"
                                                    value={fileHeaderName}
                                                    onChange={e =>
                                                        handleHeaderAndId(e)
                                                    }
                                                >
                                                    {headerNames &&
                                                        headerNames.map(
                                                            (a, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        a.fileHeader
                                                                    }
                                                                >
                                                                    {
                                                                        a.fileHeader
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                minWidth: '110px',
                                                marginLeft: '20px',
                                                textAlign: 'right'
                                            }}
                                        >
                                            User Headers
                                        </Typography>

                                        <FormControl fullWidth>
                                            <Select
                                                style={{
                                                    marginLeft: '10px',
                                                    width: '200px'
                                                }}
                                                size="small"
                                                onChange={e =>
                                                    handleDropdownChange(e)
                                                }
                                                value={userHeaderName}
                                            >
                                                {UserHeaderDropdown.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            name="brokenSubmit"
                                            onClick={e => handleSubmit(e)}
                                            sx={{
                                                ml: '20px',
                                                width: '150px',
                                                bgcolor: '#555555',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                color: '#ffffff',
                                                '&:hover': {
                                                    bgcolor: '#111111',
                                                    color: '#ffffff'
                                                }
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </div>
                            </Grid>
                            <Box
                                sx={{
                                    marginTop: 4,
                                    marginBottom: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    border: '0.5px solid #e4e4e4',
                                    borderRadius: '3px'
                                }}
                            >
                                {isSupplierSelected && listData && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            // marginTop: 4,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            lineheight: '5px'
                                        }}
                                    >
                                        {/* <ThemeProvider theme={theme2}> */}
                                        <Grid
                                            container
                                            className="grid-table-header"
                                            style={{
                                                backgroundColor:
                                                    colors.grey[800],
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                border: `0.5px solid ${colors.grey[800]}`,
                                                outline: 'none',
                                                paddingTop: '10px',
                                                paddingBottom: '10px'
                                            }}
                                            sx={{
                                                '& .MuiGrid-item': {
                                                    padding: '6px'
                                                }
                                            }}
                                        >
                                            <Grid item xs={5.5}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        paddingLeft: '5px'
                                                    }}
                                                >
                                                    File Header
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5.5}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    User Header
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography></Typography>
                                            </Grid>
                                        </Grid>
                                        {/* </ThemeProvider> */}
                                    </Box>
                                )}
                                {isSupplierSelected && listData && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            lineheight: '5px'
                                        }}
                                        // style={{
                                        //     border: '1px solid grey',
                                        //     borderBottomLeftRadius: '2px',
                                        //     borderBottomRightRadius: '2px'
                                        // }}
                                    >
                                        {/* <ThemeProvider theme={theme2}> */}
                                        <Box sx={{ width: '100%' }}>
                                            {listData.map(
                                                listItem =>
                                                    listItem.headerName !==
                                                        null && (
                                                        <div key={listItem.id}>
                                                            {editingHeaderId ===
                                                            listItem.id ? (
                                                                <Grid
                                                                    container
                                                                    className="grid-table-container"
                                                                    sx={{
                                                                        '& .MuiGrid-item':
                                                                            {
                                                                                padding:
                                                                                    '6px'
                                                                            }
                                                                    }}
                                                                    // style={{
                                                                    //     backgroundColor:
                                                                    //         listItem.id %
                                                                    //             2 ===
                                                                    //         0
                                                                    //             ? 'inherit'
                                                                    //             : colors
                                                                    //                   .primary[400]
                                                                    // }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={5.5}
                                                                    >
                                                                        <Typography
                                                                            noWrap
                                                                            style={{
                                                                                overflow:
                                                                                    'hidden',
                                                                                fontSize:
                                                                                    '13px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                listItem.fileHeader
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={5.5}
                                                                    >
                                                                        <FormControl
                                                                            fullWidth
                                                                        >
                                                                            <Select
                                                                                style={{
                                                                                    width: '200px'
                                                                                }}
                                                                                size="small"
                                                                                onBlur={e =>
                                                                                    setNewHeader(
                                                                                        {
                                                                                            ...newHeader,
                                                                                            headerName:
                                                                                                e.target.value.trim()
                                                                                        }
                                                                                    )
                                                                                }
                                                                                onChange={e =>
                                                                                    handleHeaderNameEdit(
                                                                                        e
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    newHeader.headerName
                                                                                }
                                                                            >
                                                                                {UserHeaderDropdown.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => (
                                                                                        <MenuItem
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            value={
                                                                                                item
                                                                                            }
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    '12px'
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item
                                                                                            }
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                        style={{
                                                                            padding:
                                                                                '0px'
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'space-between'
                                                                            }}
                                                                        >
                                                                            <Tooltip title="Save">
                                                                                <IconButton
                                                                                    sx={{
                                                                                        color: grey[500],
                                                                                        '&:hover':
                                                                                            {
                                                                                                scale: 1.5,
                                                                                                color: grey[700],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        color: '#6870fa'
                                                                                                    }
                                                                                            }
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleSaveEdit(
                                                                                            newHeader
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <DoneIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Cancel">
                                                                                <IconButton
                                                                                    sx={{
                                                                                        mr: isSmallScreen
                                                                                            ? 0
                                                                                            : 1,
                                                                                        color: grey[500],
                                                                                        '&:hover':
                                                                                            {
                                                                                                scale: 1.5,
                                                                                                color: 'red'
                                                                                            }
                                                                                    }}
                                                                                    onClick={
                                                                                        handleCancelEdit
                                                                                    }
                                                                                >
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                <Grid
                                                                    container
                                                                    className="grid-table-container"
                                                                    style={{
                                                                        borderBottomLeftRadius:
                                                                            '2px',
                                                                        borderBottomRightRadius:
                                                                            '2px'
                                                                        // backgroundColor:
                                                                        //     listItem.id %
                                                                        //         2 ===
                                                                        //     0
                                                                        //         ? 'inherit'
                                                                        //         : colors
                                                                        //               .primary[400]
                                                                    }}
                                                                    sx={{
                                                                        '& .MuiGrid-item':
                                                                            {
                                                                                padding:
                                                                                    '6px'
                                                                            }
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={5.5}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '5px',

                                                                                overflow:
                                                                                    'hidden',
                                                                                fontSize:
                                                                                    '13px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                listItem.fileHeader
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={5.5}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                overflow:
                                                                                    'hidden',
                                                                                fontSize:
                                                                                    '13px'
                                                                            }}
                                                                        >
                                                                            {
                                                                                listItem.headerName
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                        style={{
                                                                            padding:
                                                                                '0px',
                                                                            textAlign:
                                                                                'center'
                                                                        }}
                                                                    >
                                                                        {/* <DeleteIcon
                                                        sx={{
                                                            color: grey[500],
                                                            '&:hover': {
                                                                scale: 1.5,
                                                                color: grey[700]
                                                            },
                                                            position: 'relative'
                                                        }}
                                                        onClick={e =>
                                                            handleOpen(
                                                                userId,
                                                                listItem.id
                                                            )
                                                        }
                                                    /> */}
                                                                        <Tooltip
                                                                            title="Edit"
                                                                            placement="right"
                                                                        >
                                                                            <IconButton
                                                                                aria-label="edit"
                                                                                onClick={e =>
                                                                                    handleEditClick(
                                                                                        listItem
                                                                                    )
                                                                                }
                                                                            >
                                                                                <EditIcon
                                                                                    sx={{
                                                                                        color: grey[500],
                                                                                        '&:hover':
                                                                                            {
                                                                                                color: '#6870fa'
                                                                                            },
                                                                                        fontSize:
                                                                                            '1rem'
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </div>
                                                    )
                                            )}
                                        </Box>
                                        {/* </ThemeProvider> */}
                                    </Box>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    fontSize: '10px'
                                }}
                            >
                                <Tooltip title="Previous">
                                    <Button
                                        sx={{
                                            width: '50px',
                                            bgcolor: '#555555',
                                            marginTop: '15px',
                                            color: '#ffffff',
                                            // height: '35px',
                                            // fontSize: '13px',
                                            // marginLeft: '50px',
                                            '&:hover': {
                                                bgcolor: '#111111',
                                                color: '#ffffff'
                                            }
                                        }}
                                        onClick={e => {
                                            navigate(`/upload`);
                                        }}
                                    >
                                        &lt;&lt;
                                    </Button>
                                </Tooltip>
                                <div>
                                    <Button
                                        variant="contained"
                                        name="brokenSubmit"
                                        onClick={handleReadData}
                                        sx={{
                                            mt: 2,
                                            width: '150px',
                                            backgroundColor: isHeaderMapped
                                                ? '#4CCEAC'
                                                : '#555555',
                                            textTransform: 'none',
                                            // fontSize: '13px',
                                            color: isHeaderMapped
                                                ? '#000000'
                                                : '#ffffff',
                                            '&:hover': {
                                                bgcolor: isHeaderMapped
                                                    ? 'rgb(76,170,150)'
                                                    : '#111111',
                                                color: isHeaderMapped
                                                    ? '#000000'
                                                    : '#ffffff'
                                            }
                                        }}
                                        disabled={
                                            headerNameCount === 0
                                            // listData?.length === 0
                                            // !isSupplierSelected ||
                                            // listData?.length === 0 ||
                                            // !isHeaderMapped
                                        }
                                    >
                                        Read File Data
                                    </Button>
                                    <Tooltip title="Next">
                                        <span>
                                            <Button
                                                sx={{
                                                    width: '50px',
                                                    bgcolor: '#555555',
                                                    marginTop: '15px',
                                                    backgroundColor:
                                                        headerNameCount === 0
                                                            ? '#999999'
                                                            : '#4CCEAC',
                                                    color:
                                                        headerNameCount === 0
                                                            ? '#ffffff'
                                                            : '#000000',
                                                    // height: '35px',
                                                    // fontSize: '13px',
                                                    marginLeft: '25px',
                                                    '&:hover': {
                                                        bgcolor: '#111111',
                                                        color: '#ffffff'
                                                    }
                                                }}
                                                onClick={e => {
                                                    navigate(`/file-data`);
                                                }}
                                                disabled={
                                                    !isSupplierSelected &&
                                                    headerNameCount === 0
                                                }
                                                // disabled={!fileReadSuccessful}
                                            >
                                                &gt;&gt;
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </Box>
                        </Grid>
                    </>
                )}
            </Box>
            {showMessage && (
                <MessageModal
                    message={message}
                    setShowMessage={setShowMessage}
                />
            )}
            {/* <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={myStyle}>
                        <Typography
                            id="modal-modal-title"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                            variant="body1"
                            component="h2"
                        >
                            Are you sure to delete this header mapping?
                        </Typography>
                        <Box
                            style={{
                                width: isSmallScreen ? '100%' : '70%',
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginTop: '20px'
                            }}
                        >
                            <Button
                                style={{
                                    width: '105px',
                                    backgroundColor: '#555555',
                                    borderRadius: '5px',
                                    border: '1px solid #555555',
                                    boxShadow: 'none'
                                }}
                                onClick={e => handleOkDelete()}
                                type="submit"
                                variant="contained"
                            >
                                Ok
                            </Button>{' '}
                            <Button
                                type="cancel"
                                onClick={handleClose}
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    width: '100px',
                                    color: '#555555',
                                    backgroundColor: 'inherit',
                                    border: '1px solid #555555',
                                    borderRadius: '5px'
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div> */}
        </Box>
    );
};
export default HeaderMapping;
