import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from './baseUrl';
import axios from 'axios';

export const fetchFileHeaders = createAsyncThunk(
    'file/fetchFileHeaders',
    async userId => {
        try {
            const response = await axios.get(
                `${baseUrl}/api/files/fileHeaders/${userId}`
            );
            return response.data;
        } catch (error) {
            throw Error(error);
        }
    }
);

export const clearFileHeaders = createAsyncThunk(
    'file/clearFileHeaders',
    async () => {
        return;
    }
);

export const fetchMappedHeaders = createAsyncThunk(
    'data/fetchMappedHeaders',
    async userId => {
        const response = await axios.get(
            `${baseUrl}/api/files/fileHeaders/${userId}`
        );
        return response.data;
    }
);

export const deleteMapping = createAsyncThunk(
    'data/deleteMapping',
    async ({ userId, id }) => {
        const response = await axios.delete(
            `${baseUrl}/api/files/fileHeaders/${userId}/${id}`
        );

        return response.data;
    }
);

export const deleteFile = createAsyncThunk(
    'data/deleteFile',
    async ({ userId, idToDelete }) => {
        const response = await axios.delete(
            `${baseUrl}/api/delete/${userId}/${idToDelete}`
        );

        return response.data;
    }
);

const initialState = {
    headerNames: [],
    status: 'idle',
    error: null
};

const fetchFileHeadersSlice = createSlice({
    name: 'fetchFileHeaders',
    initialState,
    reducers: {},
    records: [],
    extraReducers: builder => {
        builder
            .addCase(fetchFileHeaders.pending, state => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchFileHeaders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.headerNames = action.payload;
            })
            .addCase(fetchFileHeaders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(clearFileHeaders.pending, state => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(clearFileHeaders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.headerNames = [];
            })
            .addCase(clearFileHeaders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchMappedHeaders.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchMappedHeaders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records = action.payload;
            })
            .addCase(fetchMappedHeaders.rejected, (state, action) => {
                state.status = 'failed';
                state.colMapError = action.error.message;
            });
        builder.addCase(deleteMapping.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isColumnDeleted = true;
            const deletedUserId = action.payload;
            state.records = state.records.filter(
                record => record.id !== deletedUserId
            );
        });
        builder.addCase(deleteFile.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isFileDeletedSuccessfully = true;
            // const deletedUserId = action.payload;
            state.records = [];
        });
    }
});

export default fetchFileHeadersSlice.reducer;
