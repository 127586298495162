import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from './baseUrl';
import axios from 'axios';

export const login = createAsyncThunk('file/login', async loginData => {
    try {
        const response = await axios.post(`${baseUrl}/api/login`, loginData);
        return response.data;
    } catch (error) {
        if (
            // error?.response
            //  &&
            error?.response?.data
            // &&
            // error?.response?.data?.message
        ) {
            throw Error(error?.response?.data);
        } else {
            throw Error(error?.response?.data);
        }
    }
});

export const logout = createAsyncThunk('file/logout', async () => {
    return false;
});

const initialState = {
    loginStatus: false,
    status: 'idle',
    error: null
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(login.pending, state => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loginStatus = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(logout.pending, state => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loginStatus = action.payload;
            })
            .addCase(logout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default loginSlice.reducer;
