import React, { useRef, useState, useEffect, useContext } from 'react';
import MessageModal from '../messageModal/MessageModal';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Button,
    TextField,
    InputLabel,
    useTheme,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    Stack,
    Alert,
    Modal,
    InputAdornment,
    Select,
    MenuItem
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';

import { Formik } from 'formik';
import Pagination from '@mui/material/Pagination';

import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import baseUrl from '../../slices/baseUrl';
import Header from '../../components/Header';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { tokens } from '../../theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';

import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
    updateUser,
    deleteUser,
    fetchTotalUserRows,
    fetchAllUsersPageWise
} from '../../slices/userSlice';

const UserList = () => {
    const theme = useTheme();
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch();

    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    const colors = tokens(theme.palette.mode);
    const [userStatus, setUserStatus] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [errorUpdateMsg, setErrorUpdateMsg] = useState('');

    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        mobileNo: '',
        email: '',
        // role: '',
        status: ''
    });

    // pagination
    const [inputPage, setInputPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingUserId, setEditingUserId] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const totalRows = useSelector(state => state.user.totalRows);
    const users = useSelector(state => state.user.users);

    useEffect(() => {
        dispatch(fetchTotalUserRows());
        dispatch(fetchAllUsersPageWise(currentPage));
        // getAllUsersList(currentPage);
    }, []);

    useEffect(() => {
        // Initialize user status from data
        const initialUserStatus = {};
        data.forEach(user => {
            initialUserStatus[user.id] = user.status === 'Active';
        });
        setUserStatus(initialUserStatus);
    }, [data]);

    useEffect(() => {
        dispatch(fetchAllUsersPageWise(currentPage));
        dispatch(fetchTotalUserRows());
    }, [currentPage]);

    useEffect(() => {
        if (totalRows > 1) {
            setTotalPages(prevPages => (prevPages = Math.ceil(totalRows / 20)));
        }
    }, [totalRows]);

    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const [roleToDelete, setRoleToDelete] = useState('');
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mailToChangePassword, setMailToChangePassword] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        mobileNo: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        role: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            firstName: '',
            username: '',
            lastName: '',
            mobileNo: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: ''
        });
        setErrorMsg(prevValue => (prevValue = ''));
    };

    const handleDeleteOpen = item => {
        setDeleteOpen(true);
        setRoleToDelete(item?.role);
        setIdToDelete(item?.id);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setIdToDelete('');
        setRoleToDelete('');
    };

    const handleOkDelete = () => {
        dispatch(deleteUser(idToDelete)).then(() =>
            dispatch(fetchAllUsersPageWise(currentPage))
        );
        setIdToDelete('');
        setRoleToDelete('');
        handleDeleteClose();
    };

    const navigate = useNavigate();

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        // You can add form validation logic here
        let collectedData = {
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            mobileNo: formData?.mobileNo,
            email: formData?.email,
            username: formData?.username,
            password: formData?.password,
            role: formData?.role
            // confirmPassword:formData.confirmPassword
        };

        if (formData.password === formData.confirmPassword) {
            setLoading(true);
            try {
                const response = await axios.post(
                    `${baseUrl}/api/signup`,
                    collectedData
                );

                // if (!response.ok) {
                //     const errorData = await response.json();
                //     setErrorMsg(errorData.message);
                //     return;
                // }

                if (response.status === 200) {
                    setLoading(false);
                    setMessage(`${formData?.role} successfully added`);
                    showMessageHandler();
                    setOpen(false);
                    handleClose();
                    setFormData({
                        firstName: '',
                        username: '',
                        lastName: '',
                        mobileNo: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                        role: ''
                    });
                    dispatch(fetchAllUsersPageWise(currentPage));
                }
            } catch (error) {
                console.error(error?.response?.data);
                setErrorMsg(error?.response?.data);
            }
        } else {
            alert('Check password and confirm password');
        }
    };

    const goToPage = () => {
        let newPage = Number(inputPage);

        if (newPage < 1) {
            setMessage('The minimum page value is 1');
            showMessageHandler();
        } else if (newPage > totalPages) {
            setMessage('The value exceeds the maximum page count');
            showMessageHandler();
        } else {
            setCurrentPage(prevPage => (prevPage = newPage));
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(prevPage => (prevPage = newPage));
        setInputPage(newPage);
    };

    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    const handleEditClick = item => {
        setEditingUserId(item.id);
        setNewUser(item);
        setUserStatus(prevStatus => ({
            ...prevStatus,
            [item.id]: item.status === 'Active'
        }));
    };

    const handleStatusChange = (userId, event) => {
        setUserStatus(prevStatus => ({
            ...prevStatus,
            [userId]: event.target.checked
        }));
    };

    const handleCancelEdit = () => {
        setEditingUserId(null);
        setNewUser({ userName: '', userEmail: '', userRole: '' });
        setErrorUpdateMsg('');
    };

    const handleSaveEdit = newUser => {
        const updatedUser = {
            ...newUser,
            status: userStatus[newUser.id] ? 'Active' : 'InActive'
        };

        axios
            .put(`${baseUrl}/api/user/updateUser/${newUser.id}`, updatedUser)
            .then(response => {
                if (response.status === 200) {
                    setEditingUserId(null);
                    setNewUser({});
                    setMessage(`${newUser.role} updated successfully`);
                    showMessageHandler();
                    dispatch(fetchAllUsersPageWise(currentPage));
                } else {
                    // Handle other cases if needed
                }
            })
            .catch(error => {
                console.error(error);
                setErrorUpdateMsg(error?.response?.data);
            });
    };

    const myStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#d0d0d0',
        width: '40vw',
        height: '150px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        outline: 'none'
    };

    // password change
    const handleChangePasswordOpen = email => {
        setMailToChangePassword(email);
        setPasswordOpen(true);
    };

    const handlePasswordClose = () => {
        setPasswordOpen(false);
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleMsgClose = () => {
        setMsgOpen(false);
    };
    const handleSuccessClose = () => {
        setSuccessOpen(false);
    };

    const handleNewPasswordChange = e => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevShowPassword => !prevShowPassword);
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setMsgOpen(true);
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else {
            await axios
                .put(`${baseUrl}/api/forgetpassword/${mailToChangePassword}`, {
                    password: newPassword
                })
                .then(response => {
                    if (response.status === 200) {
                        setMessage('Password reset successfully');
                        showMessageHandler();
                        setNewPassword('');
                        setConfirmPassword('');
                        setSuccessOpen(true);
                        setTimeout(() => {
                            handleSuccessClose();
                            handlePasswordClose();
                        }, 2000);
                    }
                })
                .catch(colMapError => {
                    setMessage(colMapError);
                    showMessageHandler();
                });
        }
    };

    const handleErrorClear = () => {
        setErrorMsg(prevValue => (prevValue = ''));
    };

    const handleUpdateErrorClear = () => {
        setErrorUpdateMsg(prevValue => (prevValue = ''));
    };

    return (
        <Box m="20px">
            {/* <Header
                title="CREATE SUPPLIERS"
                subtitle="Create a New Supplier Profile"
            /> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputLabel
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        '@media (max-width: 712px)': { fontSize: 15 }
                    }}
                >
                    Admin List
                </InputLabel>
                <Button
                    sx={{
                        width: '150px',
                        bgcolor: '#555555',
                        color: '#ffffff',
                        boxShadow: 'none',
                        textTransform: 'none',
                        '&:hover': {
                            bgcolor: '#111111',
                            color: '#ffffff'
                        }
                    }}
                    onClick={handleClickOpen}
                >
                    Add
                </Button>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    lineheight: '5px'
                }}
            >
                <Grid
                    container
                    className="grid-table-header"
                    style={{
                        backgroundColor: colors.grey[800],
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        border: `0.5px solid ${colors.grey[800]}`,
                        outline: 'none',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }}
                    sx={{
                        '& .MuiGrid-item': {
                            padding: '6px'
                        }
                    }}
                >
                    <Grid item xs={0.5}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            ID
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            First Name
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Last Name
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Mobile No
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={1.1}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Role
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Status
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <Typography
                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            Action
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={0.8} style={{ textAlign: 'center' }}>
                        <Typography
                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                        >
                            Change Password
                        </Typography>
                    </Grid> */}
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    lineheight: '5px'
                }}
            >
                <Box sx={{ width: '100%' }}>
                    {users?.map((item, index) => (
                        <div key={item?.id}>
                            {editingUserId === item.id ? (
                                <>
                                    <Grid
                                        container
                                        onChange={handleUpdateErrorClear}
                                        className="grid-table-container"
                                        style={{
                                            borderBottomLeftRadius:
                                                index === data.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            borderBottomRightRadius:
                                                index === data.length - 1
                                                    ? '5px'
                                                    : '0px',
                                            border: `0.5px solid ${colors.grey[800]}`,
                                            borderCollapse: 'collapse',
                                            outline: 'none',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? 'inherit'
                                                    : colors.primary[400]
                                        }}
                                        sx={{
                                            '& .MuiGrid-item': {
                                                padding: '6px'
                                            }
                                        }}
                                    >
                                        <Grid item xs={0.5}>
                                            <Typography
                                                style={{
                                                    // paddingLeft: '5px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newUser.firstName}
                                                onChange={e =>
                                                    setNewUser({
                                                        ...newUser,
                                                        firstName:
                                                            e.target.value.trim()
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newUser.lastName}
                                                onChange={e =>
                                                    setNewUser({
                                                        ...newUser,
                                                        lastName:
                                                            e.target.value.trim()
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1.2}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newUser.mobileNo}
                                                onChange={e =>
                                                    setNewUser({
                                                        ...newUser,
                                                        mobileNo:
                                                            e.target.value.trim()
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                required
                                                size="small"
                                                value={newUser.email}
                                                onChange={e =>
                                                    setNewUser({
                                                        ...newUser,
                                                        email: e.target.value.trim()
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1.1}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item?.role}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.2}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            userStatus[item.id]
                                                        }
                                                        size="small"
                                                        onChange={event =>
                                                            handleStatusChange(
                                                                item.id,
                                                                event
                                                            )
                                                        }
                                                        name={`userStatus-${item.id}`}
                                                        color={
                                                            userStatus[item.id]
                                                                ? 'primary'
                                                                : 'secondary'
                                                        }
                                                        // Change the color of the switch directly
                                                        style={{
                                                            color: userStatus[
                                                                item.id
                                                            ]
                                                                ? 'green'
                                                                : 'red'
                                                        }}
                                                    />
                                                }
                                                label={
                                                    userStatus[item.id]
                                                        ? 'Active'
                                                        : 'InActive'
                                                }
                                            />
                                            {/* {item?.status} */}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                padding: '0px'
                                            }}
                                        >
                                            <Tooltip title="Save">
                                                <IconButton
                                                    sx={{
                                                        '&:hover': {
                                                            color: '#6870fa'
                                                        }
                                                    }}
                                                    onClick={() =>
                                                        handleSaveEdit(newUser)
                                                    }
                                                >
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <IconButton
                                                    sx={{
                                                        mr: 1,
                                                        '&:hover': {
                                                            color: 'red'
                                                        }
                                                    }}
                                                    onClick={handleCancelEdit}
                                                >
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        {errorUpdateMsg && (
                                            <Box mt="10px">
                                                <Alert severity="error">
                                                    {errorUpdateMsg}
                                                </Alert>
                                            </Box>
                                        )}
                                    </Grid>

                                    {/* {error?.response?.data} */}
                                </>
                            ) : (
                                <Grid
                                    container
                                    className="grid-table-container"
                                    style={{
                                        borderBottomLeftRadius:
                                            index === data.length - 1
                                                ? '5px'
                                                : '0px',
                                        borderBottomRightRadius:
                                            index === data.length - 1
                                                ? '5px'
                                                : '0px',
                                        border: `0.5px solid ${colors.grey[800]}`,
                                        borderCollapse: 'collapse',
                                        outline: 'none',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        backgroundColor:
                                            index % 2 === 0
                                                ? 'inherit'
                                                : colors.primary[400]
                                    }}
                                    sx={{
                                        '& .MuiGrid-item': {
                                            padding: '6px'
                                        }
                                    }}
                                >
                                    <Grid item xs={0.5}>
                                        <Typography
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.firstName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.mobileNo}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {item?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.1}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {' '}
                                            {item?.role}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '12px',
                                                color:
                                                    item?.status === 'Active'
                                                        ? 'green'
                                                        : 'red'
                                            }}
                                        >
                                            {item?.status}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            padding: '0px'
                                        }}
                                    >
                                        <Tooltip title="Edit">
                                            <IconButton
                                                aria-label="edit"
                                                sx={{
                                                    marginRight: '5px',
                                                    // color: '#6870fa',
                                                    '&:hover': {
                                                        color: '#6870fa'
                                                    }
                                                }}
                                                onClick={e =>
                                                    handleEditClick(item)
                                                }
                                            >
                                                <EditIcon
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                aria-label="delete"
                                                sx={{
                                                    marginRight: '5px',
                                                    '&:hover': {
                                                        color: 'red'
                                                    }
                                                    // color: 'rgba(255,0,0,0.5)'
                                                }}
                                                onClick={e =>
                                                    handleDeleteOpen(item)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    ))}
                </Box>
                {/* </Box> */}
            </Box>

            {/* pagination */}
            <Grid
                container
                style={{
                    display: 'flex',
                    overflow: 'hidden',
                    position: 'sticky',
                    bottom: '-17px',
                    left: '-10px',
                    right: 0,
                    margin: '20px 0 -30px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary[400],
                    padding: '7px',
                    zIndex: 11
                }}
            >
                <Stack
                    spacing={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                    <Box style={{ marginLeft: '10px', marginTop: '0px' }}>
                        <span>| Go to page : &nbsp;</span>
                        <input
                            type="number"
                            value={inputPage === 0 ? '' : inputPage}
                            // onChange={e => {
                            //     let newPage = e.target.value
                            //         ? Math.min(
                            //               Math.max(Number(e.target.value), 1),
                            //               totalPages
                            //           )
                            //         : 1;
                            // }}
                            onChange={e =>
                                setInputPage(
                                    prevPage =>
                                        (prevPage = Number(e.target.value))
                                )
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    goToPage();
                                    e.target.blur(); // Remove focus from the input field
                                }
                            }}
                            style={{
                                width: '50px',
                                height: '30px',
                                backgroundColor: 'inherit',
                                border: '0.5px solid grey',
                                borderRadius: '3px',
                                color: colors.primary[100]
                            }}
                            min="1"
                            max={totalPages}
                        />
                        <Button
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: '#555555',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#666666'
                                },
                                width: '40px'
                            }}
                            onClick={goToPage}
                        >
                            Go
                        </Button>
                    </Box>
                </Stack>
            </Grid>

            {open && (
                <Dialog
                    open={open}
                    // TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>
                        <strong>Add new Admin/Manager</strong>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'red'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form
                            onSubmit={handleSubmit}
                            onChange={handleErrorClear}
                        >
                            <Box
                                display="grid"
                                gap="20px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    '& > div': {
                                        gridColumn: isNonMobile
                                            ? undefined
                                            : 'span 4'
                                    }
                                }}
                            >
                                <TextField
                                    required
                                    autoFocus
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    type="text"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    sx={{ gridColumn: 'span 2' }}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    size="small"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    name="lastName"
                                    sx={{ gridColumn: 'span 2' }}
                                />

                                <TextField
                                    required
                                    size="small"
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="email"
                                    sx={{ gridColumn: 'span 2' }}
                                />

                                <TextField
                                    select
                                    required
                                    size="small"
                                    variant="filled"
                                    label="Role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    name="role"
                                    sx={{ gridColumn: 'span 2' }}
                                >
                                    <MenuItem value="Admin">Admin</MenuItem>
                                    <MenuItem value="Manager">Manager</MenuItem>
                                </TextField>

                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    type="text"
                                    label="Contact Number"
                                    value={formData.mobileNo}
                                    onChange={handleChange}
                                    name="mobileNo"
                                    sx={{ gridColumn: 'span 2' }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    type="text"
                                    label="Username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    name="username"
                                    sx={{ gridColumn: 'span 2' }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    name="password"
                                    sx={{ gridColumn: 'span 2' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    sx={{ gridColumn: 'span 2' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleToggleConfirmPasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="end"
                                mt="20px"
                            ></Box>
                            {errorMsg && (
                                <Box mt="10px">
                                    <Alert severity="error">{errorMsg}</Alert>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gridColumn: 'span 4'
                                }}
                            >
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                    // onSubmit={handleFormSubmit}
                                >
                                    Create New Admin/Manager
                                </Button>
                            </Box>
                        </form>
                        {/* )}
                        </Formik> */}
                    </DialogContent>
                </Dialog>
            )}

            {passwordOpen && (
                <Dialog open={passwordOpen} onClose={handlePasswordClose}>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{ fontSize: '13px', textAlign: 'center' }}
                        >
                            Please enter new password to update old password
                        </DialogContentText>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    Email Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    size="small"
                                    autoFocus
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    value={mailToChangePassword}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    New Password
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    size="small"
                                    margin="dense"
                                    id="newPassword"
                                    label="New Password"
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    Confirm New Password
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    size="small"
                                    margin="dense"
                                    id="confirmPassword"
                                    label="Confirm New Password"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleToggleConfirmPasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                textTransform: 'none',
                                marginRight: '10px',
                                backgroundColor: 'rgb(200,50,50)',
                                '&:hover': {
                                    backgroundColor: 'rgb(139,0,0)'
                                }
                            }}
                            size="small"
                            onClick={handlePasswordClose}
                            color="primary"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'none'
                                // backgroundColor: 'rgb(50,250,50)'
                            }}
                            size="small"
                            onClick={handleChangePassword}
                            color="secondary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </DialogActions>

                    {msgOpen && (
                        <Dialog open={msgOpen} close={handleMsgClose}>
                            <DialogTitle>Alert !</DialogTitle>
                            <DialogContent>
                                <p>
                                    Password and Confirm password are not same{' '}
                                </p>
                            </DialogContent>
                        </Dialog>
                    )}
                    {successOpen && (
                        <Dialog open={successOpen} close={handleSuccessClose}>
                            <DialogTitle>Success !</DialogTitle>
                            <DialogContent>
                                <p>Password updated successfully!</p>
                            </DialogContent>
                        </Dialog>
                    )}
                </Dialog>
            )}

            {deleteOpen && (
                <div>
                    <Modal
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={myStyle}>
                            <Typography
                                id="modal-modal-title"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                                variant="body1"
                                component="h2"
                            >
                                Are you sure to delete this {roleToDelete}?
                            </Typography>
                            <Box
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    marginTop: '20px'
                                }}
                            >
                                <Button
                                    style={{
                                        width: '105px',
                                        backgroundColor: '#555555',
                                        borderRadius: '5px',
                                        border: '1px solid #555555',
                                        boxShadow: 'none'
                                    }}
                                    onClick={e => handleOkDelete()}
                                    type="submit"
                                    variant="contained"
                                >
                                    Ok
                                </Button>{' '}
                                <Button
                                    type="cancel"
                                    onClick={handleDeleteClose}
                                    style={{
                                        fontSize: '16px',
                                        textTransform: 'none',
                                        width: '100px',
                                        color: '#555555',
                                        backgroundColor: 'inherit',
                                        border: '1px solid #555555',
                                        borderRadius: '5px'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            )}

            {showMessage && (
                <MessageModal
                    message={message}
                    setShowMessage={setShowMessage}
                />
            )}
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    email: yup.string().email('invalid email').required('required'),
    mobileNo: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('required'),
    address: yup.string().required('required'),
    organisationName: yup.string().required('required')
});
const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    address: '',
    organisationName: ''
};

export default UserList;
