import { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DataArrayIcon from '@mui/icons-material/DataArray';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const [hoveredIcon, setHoveredIcon] = useState(null);
    let role = sessionStorage.getItem('role');

    const Item = ({ title, to, icon, selected, setSelected }) => {
        const location = useLocation();
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
        const isActive = location.pathname === to;
        const isHovered = hoveredIcon === title;
        return (
            <MenuItem
                active={isActive}
                style={{
                    color: colors.grey[100]
                }}
                onClick={() => setSelected(title)}
                onMouseEnter={() => setHoveredIcon(title)} // Set hovered icon
                onMouseLeave={() => setHoveredIcon(null)} // Reset hovered icon
                icon={
                    <Tooltip
                        placement="right"
                        title={title}
                        open={isHovered && isCollapsed}
                        arrow
                    >
                        {icon}
                    </Tooltip>
                }
            >
                <Typography>{title}</Typography>
                <Link to={to} />
            </MenuItem>
        );
    };
    return (
        <Box
            sx={{
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important'
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important'
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important'
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important'
                }
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={
                            isCollapsed ? (
                                <Tooltip
                                    placement="right"
                                    title={!isCollapsed ? 'Collapse' : 'Expand'}
                                >
                                    <MenuOutlinedIcon />
                                </Tooltip>
                            ) : undefined
                        }
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100]
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                >
                                    Beauty Blush
                                </Typography>
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    <Tooltip
                                        placement="right"
                                        title={
                                            !isCollapsed ? 'Collapse' : 'Expand'
                                        }
                                    >
                                        <MenuOutlinedIcon />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            Data
                        </Typography>
                        {(role === 'Admin' || role === 'SuperAdmin') && (
                            <>
                                <Item
                                    title="Upload Files"
                                    to="/upload"
                                    icon={<UploadFileIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    // isCollapsed={isCollapsed}
                                />
                                <Item
                                    title="Header Mapping"
                                    to="/headermapping"
                                    icon={<TextSnippetIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    // isCollapsed={isCollapsed}
                                />
                                <Item
                                    title="Data"
                                    to="/file-data"
                                    // to="/data"
                                    icon={<DataArrayIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    // isCollapsed={isCollapsed}
                                />
                            </>
                        )}

                        {(role === 'Admin' || role === 'SuperAdmin') && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '15px 0 5px 20px' }}
                                >
                                    Pages
                                </Typography>
                                <Item
                                    title="Supplier Management"
                                    to="/suppliers"
                                    icon={<PersonOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    // isCollapsed={isCollapsed}
                                />
                                <Item
                                    title="Orders"
                                    to="/orders"
                                    icon={<CalendarTodayOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    // isCollapsed={isCollapsed}
                                />
                            </>
                        )}
                        {role === 'SuperAdmin' && (
                            <Item
                                title="Access Management"
                                to="/users"
                                icon={<PeopleOutlineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                // isCollapsed={isCollapsed}
                            />
                        )}

                        {role === 'Manager' && (
                            <Item
                                title="Data"
                                to="/file-data-manager"
                                // to="/data"
                                icon={<DataArrayIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                // isCollapsed={isCollapsed}
                            />
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
