// src/components/UserForm.js
import React, { useState } from 'react';
import baseUrl from '../../slices/baseUrl';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import './registration.css';

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        username: '',
        lastName: '',
        mobileNo: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const navigate = useNavigate();

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        // You can add form validation logic here
        let collectedData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            mobileNo: formData.mobileNo,
            email: formData.email,
            username: formData.username,
            password: formData.password
            // confirmPassword:formData.confirmPassword
        };

        if (formData.password === formData.confirmPassword) {
            try {
                const response = await fetch(`${baseUrl}/api/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(collectedData)
                });

                if (!response.ok) {
                    throw new Error('');
                }
                if (response.ok) {
                    alert('User created');
                    navigate('/');
                }

                // For now, let's just navigate to the next page with the form data

                // Do something with the response data here
            } catch (error) {
                console.error(error);
            }
        } else {
            alert('Check password and confirm password');
        }
    };

    return (
        <div style={{ backgroundColor: '#ffffff' }}>
            <div
                className="overlay"
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Paper elevation={3} className="container">
                    <Typography variant="h5" align="center">
                        User Form
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />

                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                        <TextField
                            label="Mobile Number"
                            name="mobileNo"
                            value={formData.mobileNo}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />

                        <TextField
                            label="Username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />

                        <TextField
                            label="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />

                        <TextField
                            label="Confirm Password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            style={{ marginTop: '10px', marginBottom: '30px' }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ textTransform: 'none', fontSize: '16px' }}
                        >
                            Sign Up
                        </Button>
                    </form>
                </Paper>
                <div
                    style={{
                        display: 'flex',
                        width: '40%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p>If already have account? &nbsp;</p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            textTransform: 'none',
                            width: '100px'
                        }}
                        onClick={e => navigate('/')}
                    >
                        Sign In
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
