import { configureStore } from '@reduxjs/toolkit';
import uploadedFilesReducer from '../slices/uploadedFilesSlice';
import fetchFileHeadersReducer from '../slices/fetchFileHeadersSlice';
import loginReducer from '../slices/loginSlice';
import userReducer from '../slices/userSlice';
import fileDataReducer from '../slices/fileDataSlice';
import ordersReducer from '../slices/ordersSlice';
import supplierReducer from '../slices/supplierSlice';

export default configureStore({
    reducer: {
        uploadedFiles: uploadedFilesReducer,
        fetchFileHeaders: fetchFileHeadersReducer,
        login: loginReducer,
        user: userReducer,
        supplier: supplierReducer,
        fileData: fileDataReducer,
        orders: ordersReducer
    }
});
