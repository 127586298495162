import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../slices/baseUrl';

// Create an async thunk to fetch records by clientId

export const getUploadedFile = createAsyncThunk(
    'data/getUploadedFile',
    async userId => {
        const response = await axios.get(
            `${baseUrl}/api/files/templateFile/getByUserId/${userId}`
        );
        return response.data;
    }
);

export const deleteFile = createAsyncThunk(
    'data/deleteFile',
    async ({ userId, fileId }) => {
        const response = await axios.delete(
            `${baseUrl}/api/files/delete/${userId}/${fileId}`
        );

        return response.data;
    }
);

const initialState = {
    uploadedFilesLoading: false,
    status: 'idle',
    uploadedFiles: [],
    search: [],
    uploadedFilesError: null,
    isFileDeletedSuccessfully: false,
    error: ''
};

// Create the slice
const uploadedFilesSlice = createSlice({
    name: 'uploadedFiles',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getUploadedFile.pending, state => {
                state.uploadedFilesLoading = true;
                state.isFileDeletedSuccessfully = false;
            })
            .addCase(getUploadedFile.fulfilled, (state, action) => {
                state.uploadedFilesLoading = false;
                state.uploadedFiles = action.payload;
                state.error = null;
            })
            .addCase(getUploadedFile.rejected, (state, action) => {
                state.uploadedFilesLoading = false;
                state.uploadedFilesError = action.error.message;
            });
        builder.addCase(deleteFile.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.uploadedFiles = action.payload;
            state.isFileDeletedSuccessfully = true;
        });

        // builder
        //     .addCase(searchFilesByClientId.pending, state => {
        //         state.status = 'loading';
        //     })
        //     .addCase(searchFilesByClientId.fulfilled, (state, action) => {
        //         state.status = 'idle';
        //         state.search = action.payload;
        //         state.error = null;
        //     })
        //     .addCase(searchFilesByClientId.rejected, (state, action) => {
        //         state.status = 'idle';
        //         state.error = action.error.message;
        //     });
    }
});

// Export the reducer
export default uploadedFilesSlice.reducer;
