import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    useTheme,
    Typography,
    Button,
    Modal,
    Tooltip,
    Paper,
    Grid,
    TextField,
    InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorModeContext, tokens } from '../../theme';
import InputBase from '@mui/material/InputBase';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import MessageModal from '../messageModal/MessageModal';
import baseUrl from '../../slices/baseUrl';

const Topbar = props => {
    const { handleLogout } = props;
    const userId = sessionStorage.getItem('userId');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mailToChangePassword, setMailToChangePassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getUserDetails = async userId => {
        try {
            const response = await fetch(
                `${baseUrl}/api/user/getDataByUserId/${userId}`
            );

            if (!response.ok) {
                throw new Error(response.status);
            }

            const userData = await response.json();

            setUserName(userData.firstName);
            setUserEmail(userData.email);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUserDetails(userId);
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleProfile = () => {};

    const handleClose1 = () => {
        setOpen(false);
    };

    const handleOpenShowProfile = () => {
        setShowProfile(true);
    };

    const handleCloseShowProfile = () => {
        setShowProfile(false);
    };

    const myStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#d0d0d0',
        width: '40vw',
        height: '150px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px'
    };

    // password change
    const handleChangePasswordOpen = email => {
        setMailToChangePassword(email);
        setPasswordOpen(true);
    };

    const handlePasswordClose = () => {
        setPasswordOpen(false);
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleMsgClose = () => {
        setMsgOpen(false);
    };
    const handleSuccessClose = () => {
        setSuccessOpen(false);
    };

    const handleNewPasswordChange = e => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevShowPassword => !prevShowPassword);
    };

    const handleChangePassword = async () => {
        if (!newPassword) {
            setMsgOpen(true);
            setErrorMessage('Please enter New password');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (!confirmPassword) {
            setMsgOpen(true);
            setErrorMessage('Please enter Confirm password');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else if (newPassword !== confirmPassword) {
            setMsgOpen(true);
            setErrorMessage('Password and Confirm password are not same');
            setTimeout(() => {
                handleMsgClose();
            }, 2000);
        } else {
            await axios
                .put(`${baseUrl}/api/forgetpassword/${mailToChangePassword}`, {
                    password: newPassword
                })
                .then(response => {
                    if (response.status === 200) {
                        // setMessage('Password reset successfully');
                        // showMessageHandler();
                        setNewPassword('');
                        setConfirmPassword('');
                        setSuccessOpen(true);
                        setTimeout(() => {
                            handleSuccessClose();
                            handlePasswordClose();
                        }, 2000);
                    }
                })
                .catch(colMapError => {
                    setMessage(colMapError);
                    showMessageHandler();
                });
        }
    };
    const showMessageHandler = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
    };

    return (
        <>
            <Box display="flex" justifyContent="flex-end" p={0} sx={{ pr: 1 }}>
                {/* SEARCH BAR */}
                {/* <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box> */}
                <Box></Box>

                {/* ICONS */}
                <Box display="flex">
                    <Tooltip title="Mode">
                        <IconButton onClick={colorMode.toggleColorMode}>
                            {theme.palette.mode === 'dark' ? (
                                <DarkModeOutlinedIcon />
                            ) : (
                                <LightModeOutlinedIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    {/* <IconButton><NotificationsOutlinedIcon /></IconButton> */}
                    <IconButton onClick={handleOpenShowProfile}>
                        <PersonOutlinedIcon />
                    </IconButton>
                    <Tooltip title="Logout">
                        <IconButton onClick={e => handleOpen()}>
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <IconButton>
                                <SettingsOutlinedIcon />
                              </IconButton>
                              <IconButton>
                                <PersonOutlinedIcon />
                              </IconButton> */}
                </Box>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={myStyle}>
                            <Typography
                                id="modal-modal-title"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                                variant="body1"
                                component="h2"
                            >
                                Are you sure to logout?
                            </Typography>
                            <Box
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    marginTop: '20px'
                                }}
                            >
                                <Button
                                    style={{
                                        width: '105px',
                                        backgroundColor: '#555555',
                                        borderRadius: '5px',
                                        border: '1px solid #555555',
                                        boxShadow: 'none'
                                    }}
                                    onClick={e => handleLogout()}
                                    type="submit"
                                    variant="contained"
                                >
                                    Confirm
                                </Button>{' '}
                                <Button
                                    type="cancel"
                                    onClick={handleClose1}
                                    style={{
                                        width: '100px',
                                        color: '#555555',
                                        backgroundColor: 'inherit',
                                        border: '1px solid #555555',
                                        borderRadius: '5px'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            </Box>
            {showProfile && (
                <Dialog
                    scroll="paper"
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end'
                        }
                    }}
                    style={{ padding: 0, margin: 0 }}
                    PaperProps={{
                        style: {
                            marginTop: '50px',
                            width: '180px',
                            padding: '0px'
                        }
                    }}
                    open={showProfile}
                    onClose={handleCloseShowProfile}
                >
                    <DialogTitle
                        style={{
                            padding: '5px 5px 5px 10px',
                            backgroundColor: '#e5e5e5'
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems={'center'}
                        >
                            {/* <Typography variant="h6" align="center">
                                <strong>Profile</strong>
                            </Typography> */}
                            <DialogActions>
                                <IconButton
                                    onClick={e => {
                                        e.stopPropagation();
                                        setShowProfile(false);
                                    }}
                                >
                                    <CloseIcon color="warning" />
                                </IconButton>
                            </DialogActions>
                        </Box>
                    </DialogTitle>
                    <hr style={{ padding: '0px', margin: '0px' }} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '-29px'
                        }}
                    >
                        <Box
                            style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                backgroundColor: '#F28500',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '1.5rem'
                            }}
                        >
                            {userName !== '' &&
                                userName.substring(0, 1).toUpperCase()}
                        </Box>
                    </div>
                    <DialogContent
                        style={{
                            padding: '5px 5px 5px 10px',
                            marginTop: '10px'
                        }}
                    >
                        <Typography variant="h4" align="center">
                            {userName}
                        </Typography>
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{ mt: '5px', mb: '10px' }}
                        >
                            {userEmail}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{
                                    margin: '10px auto',
                                    textTransform: 'none'
                                }}
                                sx={{
                                    color: 'rgb(104,112,250)',
                                    '&:hover': {
                                        backgroundColor: '#ac1616',
                                        color: 'white'
                                    }
                                }}
                                onClick={e =>
                                    handleChangePasswordOpen(userEmail)
                                }
                                variant="outlined"
                            >
                                Change Password
                            </Button>
                        </Box>
                    </DialogContent>
                    {/* Add your content here */}
                </Dialog>
            )}
            {passwordOpen && (
                <Dialog open={passwordOpen} onClose={handlePasswordClose}>
                    <DialogTitle>
                        <strong>Change Password</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{ fontSize: '13px', textAlign: 'center' }}
                        >
                            Please enter new password to update old password
                        </DialogContentText>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Email Address
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    disabled
                                    required
                                    autoComplete="off"
                                    size="small"
                                    // autoFocus
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    value={mailToChangePassword}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    New Password
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    size="small"
                                    margin="dense"
                                    id="newPassword"
                                    label="New Password"
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontSize: '13px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Confirm New Password
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    size="small"
                                    margin="dense"
                                    id="confirmPassword"
                                    label="Confirm New Password"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleToggleConfirmPasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                textTransform: 'none',
                                marginRight: '10px',
                                backgroundColor: 'rgb(200,50,50)',
                                '&:hover': {
                                    backgroundColor: 'rgb(139,0,0)'
                                }
                            }}
                            size="small"
                            onClick={handlePasswordClose}
                            color="primary"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'none'
                                // backgroundColor: 'rgb(50,250,50)'
                            }}
                            size="small"
                            onClick={handleChangePassword}
                            color="secondary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </DialogActions>

                    {msgOpen && (
                        <Dialog open={msgOpen} onClose={handleMsgClose}>
                            <DialogTitle>Alert !</DialogTitle>
                            <DialogContent>
                                {/* <p>
                                    Password and Confirm password are not same{' '}
                                </p> */}
                                <p>{errorMessage}</p>
                            </DialogContent>
                        </Dialog>
                    )}
                    {successOpen && (
                        <Dialog open={successOpen} onClose={handleSuccessClose}>
                            <DialogTitle sx={{ fontSize: '20px' }}>
                                Success !
                            </DialogTitle>
                            <DialogContent>
                                <p>Password updated successfully!</p>
                            </DialogContent>
                        </Dialog>
                    )}
                </Dialog>
            )}
            {showMessage && (
                <MessageModal
                    message={message}
                    setShowMessage={setShowMessage}
                />
            )}
        </>
    );
};

export default Topbar;
