import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Upload from './scenes/upload';
import Invoices from './scenes/invoices';
import Data from './scenes/data';
import Bar from './scenes/bar';
import Suppliers from './scenes/suppliers';
import Line from './scenes/line';
import Pie from './scenes/pie';
import Headers from './scenes/headerMapping';
import Users from './scenes/userManagement';
import Geography from './scenes/geography';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Orders from './scenes/orders/orders';
import FileData from './scenes/fileData/FileData';
import FileDataManager from './scenes/fileData/FileDataManager';
import Login from './scenes/loginForm/Login';
import RegistrationForm from './scenes/registration/Registration';
import Settings from './scenes/settings/Settings';
import { useNavigate } from 'react-router-dom';
import './App.css';

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(() => {
        return sessionStorage.getItem('userId') !== null;
    });
    let role = sessionStorage.getItem('role') || null;

    const navigate = useNavigate();

    const handleLogin = id => {
        sessionStorage.setItem('userId', id);
        setLoggedIn(true);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        setLoggedIn(false);
        navigate('/');
    };

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {isLoggedIn ? (
                    <div className="app">
                        <Sidebar isSidebar={isSidebar} className="sidebar" />
                        <main className="content">
                            <Topbar
                                handleLogout={handleLogout}
                                tIsSidebar={setIsSidebar}
                            />
                            <Routes>
                                {/* <Route path="/" element={<Dashboard />} /> */}

                                <Route path="/upload" element={<Upload />} />
                                <Route
                                    path="/headermapping"
                                    element={<Headers />}
                                />
                                <Route path="/data" element={<Data />} />
                                <Route
                                    path="/file-data"
                                    element={<FileData />}
                                />
                                <Route
                                    path="/file-data-manager"
                                    element={<FileDataManager />}
                                />
                                <Route
                                    path="/invoices"
                                    element={<Invoices />}
                                />
                                <Route
                                    path="/suppliers"
                                    element={<Suppliers />}
                                />
                                <Route path="/bar" element={<Bar />} />
                                <Route path="/pie" element={<Pie />} />
                                <Route path="/line" element={<Line />} />

                                <Route path="/users" element={<Users />} />

                                <Route
                                    path="/settings"
                                    element={<Settings />}
                                />
                                <Route path="/orders" element={<Orders />} />
                                <Route
                                    path="/geography"
                                    element={<Geography />}
                                />
                            </Routes>
                        </main>
                    </div>
                ) : (
                    <Routes>
                        <Route
                            path="/"
                            element={<Login onLogin={handleLogin} />}
                        />
                        <Route
                            path="/registration"
                            element={<RegistrationForm />}
                        />
                        {/* <Route path="/" element={<FileData />} /> */}
                        {/* You may add other routes for non-authenticated users */}
                    </Routes>
                )}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
