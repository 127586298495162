import React from 'react';
import './messageModal.css';
import { useMediaQuery } from '@mui/material';

const MessageModal = ({ message, setShowMessage }) => {
    const handleClickClose = () => {
        setShowMessage = false;
    };
    const isSmallScreen = useMediaQuery('(max-width:850px)');

    return (
        <div className="message-modal" onClick={handleClickClose}>
            <div
                className="message-container"
                style={{
                    top: isSmallScreen ? '25%' : '50%',
                    height: isSmallScreen ? '100px' : '150px'
                }}
            >
                <div
                    className="message-content"
                    style={{ fontSize: isSmallScreen ? '12px' : '16px' }}
                >
                    <strong>{message}</strong>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;
